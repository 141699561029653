<template>
  <div class="flex flex-wrap mt-4 min-height-view">
    <div class="w-full mb-12 px-4" v-if="!showUserRoles">
      <card-users />
    </div>
    <div class="w-full mb-12 px-4" v-if="showUserRoles">
      <card-user-roles />
    </div>
  </div>
</template>
<script>
import CardUsers from "@/components/Cards/Manager/CardUsers.vue";
import CardUserRoles from "@/components/Cards/Manager/CardUserRoles.vue";

export default {
  data() {
    return {
      showUserRoles: false,
    };
  },

  components: {
    CardUsers,
    CardUserRoles,
  },

  props: {
    user: Object,
  },
};
</script>
