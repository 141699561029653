<template>
  <div>
    <superuser-sidebar/>
    <div class="relative md:ml-64 bg-blueGray-100">
      <superuser-navbar/>
      <header-dashboard />
      <div class="px-4 h-full md:px-10 mx-auto w-full -m-24 min-height-layout">
        <router-view/>
        <footer-small/>
      </div>
    </div>
  </div>
</template>
<script>
import SuperuserNavbar from "@/components/Navbars/SuperuserNavbar.vue";
import SuperuserSidebar from "@/components/Sidebar/SuperuserSidebar.vue";
import FooterSmall from "@/components/Footers/FooterSmall.vue";
import HeaderDashboard from "../components/Headers/HeaderDashboard";
import AuthService from "../services/auth.service";
export default {
  name: "superuser-layout",

  async created() {
    const token = await AuthService.getAuthToken();
    if(token.data.message !== "super")
      await this.$router.push('/');
  },

  components: {
    HeaderDashboard,
    SuperuserNavbar,
    SuperuserSidebar,
    FooterSmall,
  },
};
</script>
