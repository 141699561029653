var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded",class:[_vm.color === 'light' ? 'bg-white' : 'bg-yellow-900 text-white']},[_c('div',{staticClass:"rounded-t mb-0 px-4 py-3 border-0"},[_c('div',{staticClass:"px-3 text-center flex"},[_c('h3',{staticClass:"font-semibold text-lg",class:[_vm.color === 'light' ? 'text-blueGray-700' : 'text-white']},[_vm._v(" "+_vm._s(_vm.manager.name + ' ' + _vm.manager.surname)+" - "+_vm._s(_vm.$t('roles'))+" ")])])]),(_vm.showEditRoles)?_c('card-edit-manager-roles',{attrs:{"index":_vm.userIndex}}):_vm._e(),_c('div',{staticClass:"block w-full overflow-x-auto"},[_c('table',{staticClass:"items-center w-full bg-transparent border-collapse"},[_c('thead',[_c('tr',[_c('th',{staticClass:"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left",class:[
              _vm.color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-yellow-800 text-yellow-300 border-emerald-700' ]},[_vm._v(" Locker ")]),_c('th',{staticClass:"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left",class:[
              _vm.color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-yellow-800 text-yellow-300 border-emerald-700' ]},[_vm._v(" "+_vm._s(_vm.$t('modify_locker'))+" ")]),_c('th',{staticClass:"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left",class:[
              _vm.color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-yellow-800 text-yellow-300 border-emerald-700' ]},[_vm._v(" "+_vm._s(_vm.$t('add_users'))+" ")]),_c('th',{staticClass:"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left",class:[
              _vm.color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-yellow-800 text-yellow-300 border-emerald-700' ]})])]),_c('tbody',[_vm._l((_vm.roles),function(role,index){return [_c('tr',{key:role.id},[_c('td',{staticClass:"border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"},[_vm._v(" "+_vm._s(_vm.getLockerById(role.locker_id))+" ")]),_c('td',{staticClass:"border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"},[(role.modify_locker === 0)?_c('div',[_c('i',{staticClass:"fas fa-circle text-gray-500 mr-2"})]):(role.modify_locker === 1)?_c('div',[_c('i',{staticClass:"fas fa-circle text-emerald-500 mr-2"})]):_c('div',[_vm._v(" - ")])]),_c('td',{staticClass:"border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"},[(role.add_users === 0)?_c('div',[_c('i',{staticClass:"fas fa-circle text-gray-500 mr-2"})]):(role.add_users === 1)?_c('div',[_c('i',{staticClass:"fas fa-circle text-emerald-500 mr-2"})]):_c('div',[_vm._v(" - ")])]),_c('td',{staticClass:"border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"},[_c('user-roles-dropdown',{attrs:{"index":index}})],1)])]})],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }