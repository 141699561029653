<template>
  <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
      :class="[color === 'light' ? 'bg-white' : 'bg-yellow-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="px-3 text-center flex justify-between">
        <h3
            class="font-semibold text-lg"
            :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
        >
          {{ $t('packages') }}
        </h3>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
        <tr>
          <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-yellow-800 text-yellow-300 border-emerald-700',
              ]"
          >
            {{ $tc('deposit_code', 1) }}
          </th>
          <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-yellow-800 text-yellow-300 border-emerald-700',
              ]"
          >
            {{ $t('withdrawal_code') }}
          </th>
          <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-yellow-800 text-yellow-300 border-emerald-700',
              ]"
          >
            Locker
          </th>
          <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left d-none"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-yellow-800 text-yellow-300 border-emerald-700',
              ]"
          >
            {{ $t('deposit_date') }}
          </th>
        </tr>
        </thead>
        <tbody>
        <template v-for="pack in packages">
          <tr v-if="pack.id > 0 && pack.status !== 0" :key="pack.id">
            <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <div v-if="pack.deposit_code === ''">-</div>
              <div v-else>{{ pack.deposit_code }}</div>
            </td>
            <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <div v-if="pack.withdrawal_code === ''">-</div>
              <div v-else>{{ pack.withdrawal_code }}</div>
            </td>
            <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {{ getLockerById(pack.locker_id) }}
            </td>
            <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 d-none"
            >
              {{ pack.created_at.replace(/[TZ]/g, ' ').substr(0, 19) }}
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import AuthService from "../../../services/auth.service";

export default {
  name: 'CardPackages',
  data() {
    return {
      packages: [],
      lockers: [],
      AuthService
    };
  },

  mounted: function () {
    this.getLockerList();
    AuthService.readPackagesOfUser().then(
        response => {
          this.packages = response.data.message.reverse();
        }
    );
    window.setInterval(() => {
      AuthService.readPackagesOfUser().then(
          response => {
            this.packages = response.data.message.reverse();
          }
      );
    }, 20000)
  },

  methods: {
    getLockerList: async function () {
      let lockerList = [];
      let currentUser = await AuthService.getCurrentUser();
      this.userId = currentUser.data.message.id;
      lockerList = await AuthService.getLockersOfUser(currentUser.data.message.id);
      this.lockers = lockerList.data.message;
    },

    getLockerById(id) {
      for(let locker of this.lockers) {
        if (locker.id === id)
          return locker.name;
      }
    }
  },

  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
