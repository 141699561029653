<template>
  <!-- Navbar -->
  <nav
    class="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4"
  >
    <div
      class="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4"
    >
      <!-- Form -->
      <form
        class="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3"
      >
        <div class="relative flex w-full flex-wrap items-stretch">
          <p class="text-white text-sm hidden lg:inline-block font-semibold">
            {{ user }}
          </p>
        </div>
      </form>
      <!-- User -->
      <ul class="flex-col md:flex-row list-none items-center hidden md:flex">
        <icon-dropdown />
      </ul>
    </div>
  </nav>
  <!-- End Navbar -->
</template>

<script>
import IconDropdown from "@/components/Dropdowns/Superuser/IconDropdown.vue";
import AuthService from "../../services/auth.service";

export default {
  data() {
    return {
      user: '',
      AuthService
    };
  },
  components: {
    IconDropdown,
  },

  mounted() {
    AuthService.getCurrentSuperuser().then(
        response => {
          this.user = response.data.message.name;
        }
    );
  }
};
</script>
