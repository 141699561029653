<template>
  <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
      :class="[color === 'light' ? 'bg-white' : 'bg-yellow-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="px-3 text-center flex justify-between">
        <h3
            class="font-semibold text-lg"
            :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
        >
          {{ $t('subscribe_locker') }}
        </h3>
      </div>
    </div>
    <div class="flex flex-wrap justify-center">
      <div class="w-full lg:w-6/12 px-4 pb-4">
        <div class="relative w-full mb-3">
          <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            {{ $t('insert_locker_id') }}
          </label>
          <input
              type="text"
              placeholder="Locker ID"
              v-model="lockerId"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          />
        </div>
        <div class="flex flex-wrap justify-center">
          <button
              class="bg-yellow-400 text-white ative:bg-yellow-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button" v-on:click="subscribeToLocker">
            {{ $t('subscribe') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AuthService from "../../../services/auth.service";

export default {
  name: 'CardLockerSubscribe',
  data() {
    return {
      lockerId: '',
    };
  },

  methods: {
    subscribeToLocker: function () {
      if(!isNaN(this.lockerId)) {
        // manda la richiesta al locker
        AuthService.requestAccessToLocker(this.lockerId).then(
            response => {
              if (response.data.status === "success") {
                this.$fire({
                  title: this.$t('success'),
                  text: this.$t('request_locker_sent_succ'),
                  type: "success",
                  confirmButtonColor: "#fbbf24",
                  timer: 3000
                });
              } else {
                this.$fire({
                  title: this.$t('error'),
                  html: this.$t('error_occurred') + ".<br>(" + response.data.message + ")",
                  type: "error",
                  confirmButtonColor: "#fbbf24",
                  timer: 3000
                });
              }
            });
      } else {
        this.$fire({
          title: this.$t('error'),
          text: this.$t('insert_valid_locker_id'),
          type: "error",
          confirmButtonColor: "#fbbf24",
          timer: 3000
        });
      }
    }
  },

  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
