<template>
  <div>
    <a
        class="text-blueGray-500 py-1 px-3"
        ref="btnDropdownRef"
        v-on:click="toggleDropdown($event)"
    >
      <i class="fas fa-ellipsis-v"></i>
    </a>
    <div
        ref="popoverDropdownRef"
        class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
        v-click-outside="hideDropdown"
    >
      <a
          @click="removeUserFromLocker"
          href="javascript:void(0);"
          class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        {{ $t('remove_user') }}
      </a>
    </div>
  </div>
</template>
<script>
import { createPopper } from "@popperjs/core";
import AuthService from "../../services/auth.service";

export default {
  data() {
    return {
      dropdownPopoverShow: false,
      dropdownClicksCounter: 0,
      AuthService
    };
  },

  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },

    hideDropdown: function () {
      this.dropdownClicksCounter++;
      if(this.dropdownClicksCounter === 2) {
        this.dropdownClicksCounter = 0;
        if(this.dropdownPopoverShow)
          this.dropdownPopoverShow = !this.dropdownPopoverShow;
      }
    },

    removeUserFromLocker: function () {
      this.$fire({
        title: this.$t('confirm_remove'),
        text: this.$t('confirm_remove_user_locker'),
        type: "question",
        confirmButtonColor: "#fbbf24",
        showCancelButton: true,
      }).then(response => {
        if (response.value === true) {
          // rimuove l'utente dell'armadio
          let locker = this.$parent.locker;
          let user = this.$parent.users[this.$props.index];
          AuthService.removeUserFromLocker(locker.id, user.id).then(
              response => {
                if (response.data.status === "success") {
                  // rimuove l'utente dall'armadio
                  AuthService.getUsersOfLocker(locker.id).then(
                      response => {
                        // aggiorna l'utente dall'armadio
                        this.$parent.users = response.data.message;
                      }
                  );
                  this.$fire({
                    title: this.$t('success'),
                    text: this.$t('remove_user_locker_succ'),
                    type: "success",
                    confirmButtonColor: "#fbbf24",
                    timer: 3000
                  });
                } else {
                  this.$fire({
                    title: this.$t('error'),
                    html: this.$t('error_occurred') + ".<br>(" + response.data.message + ")",
                    type: "error",
                    confirmButtonColor: "#fbbf24",
                    timer: 3000
                  });
                }
              }
          );
        }
      });
    }
  },

  props: {
    index: Number,
  }
};
</script>
