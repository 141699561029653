import axios from 'axios';
import authHeader from "./auth-header";

const API_URL = 'https://api.mylockersolution.cloud/';

class AuthService {

    // Logins
    superuserLogin(user) {
        return axios
            .post(API_URL + 'super/login', {
                username: '',
                email: user.email,
                password: user.password
            })
            .then(response => {
                if (response.data.message) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    managerLogin(user) {
        return axios
            .post(API_URL + 'managers/login', {
                username: '',
                email: user.email,
                password: user.password
            })
            .then(response => {
                if (response.data.message) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    userLogin(user) {
        return axios
            .post(API_URL + 'standard/login', {
                username: '',
                email: user.email,
                password: user.password
            })
            .then(response => {
                if (response.data.message) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    getCurrentUser() {
        return axios.get(API_URL + 'standard/get/me', {headers: authHeader()});
    }

    getCurrentManager() {
        return axios.get(API_URL + 'managers/get/me', {headers: authHeader()});
    }

    getCurrentSuperuser() {
        return axios.get(API_URL + 'super/get/me', {headers: authHeader()});
    }

    getAuthToken() {
        return axios.get(API_URL + 'checkToken', {headers: authHeader()});
    }

    sendEmailRecover(email) {
        return axios
            .post(API_URL + 'standard/recover', {
                email: email
            })
    }

    checkRecoverToken(token) {
        return axios
            .post(API_URL + 'standard/checkRecoverToken', {
               token: token
            })
    }

    activateUser(userId, token) {
        return axios.get(API_URL + '/standard/activate/' + userId + '/' + token, {headers: authHeader()});
    }

    logout() {
        localStorage.removeItem('user');
    }

    //Superusers
    getSuperUsers() {
        return axios.get(API_URL + 'super/getAll', {headers: authHeader()});
    }

    createSuperuser(username, email, password, name, surname) {
        return axios
            .post(API_URL + 'super/register', {
                username: username,
                email: email,
                password: password,
                name: name,
                surname: surname,
            }, {headers: authHeader()})
    }

    editSuperuser(id, username, email, password, name, surname, status) {
        return axios
            .post(API_URL + 'super/update', {
                id: id,
                username: username,
                email: email,
                password: password,
                name: name,
                surname: surname,
                status: status
            }, {headers: authHeader()})
    }

    deleteSuperuser(id) {
        return axios
            .post(API_URL + 'super/remove', {
                id: id
            }, {headers: authHeader()})
    }

    // Managers
    getManagers() {
        return axios.get(API_URL + 'managers/getAll', {headers: authHeader()});
    }

    createManager(username, email, password, name, surname) {
        return axios
            .post(API_URL + 'managers/register', {
                username: username,
                email: email,
                password: password,
                name: name,
                surname: surname,
            }, {headers: authHeader()})
    }

    editManager(id, username, email, password, name, surname, status, is_new_user) {
        return axios
            .post(API_URL + 'managers/update', {
                id: id,
                username: username,
                email: email,
                password: password,
                name: name,
                surname: surname,
                status: status,
                is_new_user: is_new_user,
            }, {headers: authHeader()})
    }

    deleteManager(id) {
        return axios
            .post(API_URL + 'managers/remove', {
                id: id
            }, {headers: authHeader()})
    }

    // Users
    getUsers() {
        return axios.get(API_URL + 'standard/getAll', {headers: authHeader()});
    }

    getUserById(id) {
        return axios.get(API_URL + 'standard/get/' + id, {headers: authHeader()});
    }

    createUser(username, email, password, name, surname) {
        return axios
            .post(API_URL + 'standard/register', {
                username: username,
                email: email,
                password: password,
                name: name,
                surname: surname,
            }, {headers: authHeader()})
    }

    editUser(id, username, email, password, name, surname, is_public, changePinCode, status) {
        return axios
            .post(API_URL + 'standard/update', {
                id: id,
                username: username,
                email: email,
                password: password,
                name: name,
                surname: surname,
                is_public: is_public,
                changePinCode: changePinCode,
                status: status,
            }, {headers: authHeader()})
    }

    deleteUser(id) {
        return axios
            .post(API_URL + 'standard/remove', {
                id: id
            }, {headers: authHeader()})
    }

    // Locker operations
    getLockers() {
        return axios.get(API_URL + 'lockers/getAll', {headers: authHeader()});
    }

    getPublicLockers() {
        return axios.get(API_URL + 'lockers/getAll');
    }

    getLockerById(id) {
        return axios.get(API_URL + 'lockers/get/' + id, {headers: authHeader()});
    }

    getUsersOfLocker(id) {
        return axios.get(API_URL + 'lockers/getUsers/' + id + '/0', {headers: authHeader()});
    }

    getAwaitingUsersOfLocker(id) {
        return axios.get(API_URL + 'lockers/getUsers/' + id + '/awaiting', {headers: authHeader()});
    }

    getLockersOfUser(id) {
        return axios.get(API_URL + 'standard/getLockers/' + id, {headers: authHeader()});
    }

    getManagersOfLocker(id) {
        return axios.get(API_URL + 'lockers/getManagers/' + id, {headers: authHeader()});
    }

    getLockersOfManager(id) {
        return axios.get(API_URL + 'managers/getLockers/' + id, {headers: authHeader()});
    }

    getRolesOfUser(id) {
        return axios.get(API_URL + 'standard/getRoles/' + id, {headers: authHeader()});
    }

    getRolesOfManager(id) {
        return axios.get(API_URL + 'managers/getRoles/' + id, {headers: authHeader()});
    }

    createLocker(name, address, latitude, longitude, password) {
        return axios
            .post(API_URL + 'lockers/register', {
                name: name,
                address: address,
                latitude: latitude,
                longitude: longitude,
                password: password
            }, {headers: authHeader()})
    }

    editLocker(id, name, address, latitude, longitude, password, isPublic, status) {
        return axios
            .post(API_URL + 'lockers/update', {
                id: id,
                name: name,
                address: address,
                latitude: latitude,
                longitude: longitude,
                password: password,
                isPublic: isPublic,
                status: status
            }, {headers: authHeader()})
    }

    deleteLocker(id) {
        return axios
            .post(API_URL + 'lockers/remove', {
                id: id
            }, {headers: authHeader()})
    }

    setRolesOfUser(userId, lockerId, createMasterCode, createDepCode, createDerCode, createReturnCode) {
        return axios
            .post(API_URL + 'standard/setRoles', {
                userId: userId,
                lockerId: lockerId,
                createMasterCode: createMasterCode,
                createDepCode: createDepCode,
                createDerCode: createDerCode,
                createReturnCode: createReturnCode,
            }, {headers: authHeader()})
    }

    setRolesOfManager(userId, lockerId, modifyLocker, addUsers) {
        return axios
            .post(API_URL + 'managers/setRoles', {
                userId: userId,
                lockerId: lockerId,
                modifyLocker: modifyLocker,
                addUsers: addUsers,
            }, {headers: authHeader()})
    }

    addUserToLocker(lockerId, email) {
        return axios
            .post(API_URL + 'lockers/inviteUser', {
                lockerId: lockerId,
                email: email,
            }, {headers: authHeader()})
    }

    removeUserFromLocker(lockerId, userId) {
        return axios
            .post(API_URL + 'lockers/removeUser', {
                lockerId: lockerId,
                userId: userId,
            }, {headers: authHeader()})
    }

    approveUserToLocker(lockerId, userId) {
        return axios
            .post(API_URL + 'lockers/approveAccess', {
                lockerId: lockerId,
                userId: userId,
            }, {headers: authHeader()})
    }

    requestAccessToLocker(lockerId) {
        return axios
            .post(API_URL + 'lockers/requestAccess', {
                lockerId: lockerId,
            }, {headers: authHeader()})
    }

    addManagerToLocker(managerId, lockerId) {
        return axios
            .post(API_URL + 'managers/addToLocker', {
                managerId: managerId,
                lockerId: lockerId,
            }, {headers: authHeader()})
    }

    removeManagerFromLocker(managerId, lockerId) {
        return axios
            .post(API_URL + 'managers/removeFromLocker', {
                managerId: managerId,
                lockerId: lockerId,
            }, {headers: authHeader()})
    }

    //Drawers operations
    getDrawersByLockerId(id) {
        return axios.get(API_URL + 'drawers/getAll/' + id, {headers: authHeader()});
    }

    getDrawersStatesByLockerId(id) {
        return axios.get(API_URL + 'drawers/states/' + id, {headers: authHeader()});
    }

    createDrawer(lockerId, slaveId, solenoidId, sizeCode) {
        return axios
            .post(API_URL + 'drawers/register', {
                    lockerId: lockerId,
                    slaveId: slaveId,
                    solenoidId: solenoidId,
                    sizeCode: sizeCode
                }, {headers: authHeader()})
    }

    editDrawer(id, lockerId, slaveId, solenoidId, sizeCode, status, awaitingOperation) {
        return axios
            .post(API_URL + 'drawers/update', {
                    id: id,
                    lockerId: lockerId,
                    slaveId: slaveId,
                    solenoidId: solenoidId,
                    sizeCode: sizeCode,
                    status: status,
                    awaitingOperation,
                }, {headers: authHeader()})
    }

    deleteDrawer(lockerId, drawerId) {
        return axios
            .post(API_URL + 'drawers/remove', {
                    lockerId: lockerId,
                    drawerId: drawerId
                }, {headers: authHeader()})
    }

    openDrawer(drawerId, lockerId, empty) {
        return axios
            .post(API_URL + 'iot/openDrawer', {
                drawerId: drawerId,
                lockerId: lockerId,
                empty: empty,
            }, {headers: authHeader()})
    }

    // Codes
    createNewDepositCode(lockerId, friendlyName, isOneTime, hasRecipient, recipientName, recipientEmail) {
        return axios
            .post(API_URL + 'reservations/createDepositCode', {
                lockerId: lockerId,
                friendlyName: friendlyName,
                isOneTime: isOneTime,
                hasRecipient: hasRecipient,
                recipientName: recipientName,
                recipientEmail: recipientEmail
            }, {headers: authHeader()})
    }

    createNewReturnCode(lockerId, friendlyName) {
        return axios
            .post(API_URL + 'reservations/createReturnCode', {
                lockerId: lockerId,
                friendlyName: friendlyName,
            }, {headers: authHeader()})
    }

    readDepositCodes() {
        return axios.post(API_URL + 'reservations/readDepositCodes', {},{headers: authHeader()});
    }

    readDepositCodesWithRecipient() {
        return axios.post(API_URL + 'reservations/readDepositCodesWithRecipient', {},{headers: authHeader()});
    }

    readReturnDepositCodes() {
        return axios.post(API_URL + 'reservations/readReturnDepositCodes', {},{headers: authHeader()});
    }

    readReturnCourierCodes() {
        return axios.post(API_URL + 'reservations/readReturnCourierCodes', {},{headers: authHeader()});
    }

    deleteDepositCode(id) {
        return axios
            .post(API_URL + 'reservations/deleteDepositCode', {
                id: id
            },{headers: authHeader()})
    }

    deleteDepositCodeWithRecipient(id) {
        return axios
            .post(API_URL + 'reservations/deleteDepositCodeWithRecipient', {
                id: id
            },{headers: authHeader()})
    }

    deleteReturnDepositCode(id) {
        return axios
            .post(API_URL + 'reservations/deleteReturnDepositCode', {
                id: id
            },{headers: authHeader()})
    }

    emailUserForCode(code, email) {
        return axios
            .post(API_URL + 'reservations/remindCode', {
                email: email,
                code: code
            },{headers: authHeader()})
    }

    // Packages
    getLogsByUserId(id) {
        return axios.get(API_URL + 'standard/getLogs/' + id, {headers: authHeader()});
    }

    readPackagesOfUser() {
        return axios.post(API_URL + 'reservations/readPackages', {},{headers: authHeader()});
    }
}

export default new AuthService();
