<template>
  <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
      :class="[color === 'light' ? 'bg-white' : 'bg-yellow-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="px-3 text-center flex">
        <button
            class="bg-yellow-400 text-white ative:bg-yellow-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-4 ease-linear transition-all duration-150"
            type="button"
            v-on:click="undoToUsers()"
        >
          <i class="fas fa-arrow-left text-sm mx-auto"></i>
        </button>
        <h3
            class="font-semibold text-lg"
            :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
        >
          {{ user.name + ' ' + user.surname }} - {{ $t('roles') }}
        </h3>
      </div>
    </div>
    <card-edit-user-roles v-if="showEditRoles" v-bind:index="userIndex"/>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
        <tr>
          <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-yellow-800 text-yellow-300 border-emerald-700',
              ]"
          >
            Locker
          </th>
          <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-yellow-800 text-yellow-300 border-emerald-700',
              ]"
          >
            {{ $t('create_master_code') }}
          </th>
          <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-yellow-800 text-yellow-300 border-emerald-700',
              ]"
          >
            {{ $t('create_dep') }}
          </th>
          <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-yellow-800 text-yellow-300 border-emerald-700',
              ]"
          >
            {{ $t('create_der') }}
          </th>
          <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-yellow-800 text-yellow-300 border-emerald-700',
              ]"
          >
            {{ $t('create_return') }}
          </th>
          <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-yellow-800 text-yellow-300 border-emerald-700',
              ]"
          ></th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(role, index) in roles">
          <tr :key="role.id">
            <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {{ getLockerById(role.locker_id) }}
            </td>
            <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <div v-if="role.create_master_code === 0">
                <i class="fas fa-circle text-gray-500 mr-2"></i>
              </div>
              <div v-else-if="role.create_master_code === 1">
                <i class="fas fa-circle text-emerald-500 mr-2"></i>
              </div>
              <div v-else>
                -
              </div>
            </td>
            <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <div v-if="role.create_dep_code === 0">
                <i class="fas fa-circle text-gray-500 mr-2"></i>
              </div>
              <div v-else-if="role.create_dep_code === 1">
                <i class="fas fa-circle text-emerald-500 mr-2"></i>
              </div>
              <div v-else>
                -
              </div>
            </td>
            <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <div v-if="role.create_der_code === 0">
                <i class="fas fa-circle text-gray-500 mr-2"></i>
              </div>
              <div v-else-if="role.create_der_code === 1">
                <i class="fas fa-circle text-emerald-500 mr-2"></i>
              </div>
              <div v-else>
                -
              </div>
            </td>
            <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <div v-if="role.create_return_code === 0">
                <i class="fas fa-circle text-gray-500 mr-2"></i>
              </div>
              <div v-else-if="role.create_return_code === 1">
                <i class="fas fa-circle text-emerald-500 mr-2"></i>
              </div>
              <div v-else>
                -
              </div>
            </td>
            <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
            >
              <user-roles-dropdown v-bind:index="index"/>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import CardEditUserRoles from "@/components/Cards/Superuser/CardEditUserRoles.vue";
import UserRolesDropdown from "@/components/Dropdowns/Superuser/UserRolesDropdown.vue";
import AuthService from "../../../services/auth.service";

export default {
  name: 'Locker',
  data() {
    return {
      user: '',
      roles: [],
      lockers: [],
      showEditRoles: false,
      userIndex: '',
      AuthService
    };
  },

  mounted: function () {
    this.user = this.$parent.$props.user;
    AuthService.getLockers().then(
        response => {
          this.lockers = response.data.message;
        }
    );
    AuthService.getRolesOfUser(this.user.id).then(
        response => {
          this.roles = response.data.message;
        }
    );
    window.setInterval(() => {
      AuthService.getRolesOfUser(this.user.id).then(
          response => {
            this.roles = response.data.message;
          }
      );
    }, 20000)
  },

  methods: {
    undoToUsers() {
      this.$parent.showUserRoles = false;
    },

    toggleEditRoles: function () {
      this.showEditRoles = !this.showEditRoles;
    },

    getLockerById(id) {
      for(let locker of this.lockers) {
        if (locker.id === id)
          return locker.name;
      }
    },

    setIndex: function(index) {
      this.userIndex = index;
    }
  },

  components: {
    UserRolesDropdown,
    CardEditUserRoles,
  },

  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
