<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container-500">
        <div
            class="relative flex flex-col min-w-0 break-words w-full rounded-lg bg-blueGray-100 border-0"
        >
          <div class="rounded-t bg-white mb-0 px-6 py-6">
            <div class="text-center flex justify-between">
              <h6 class="text-blueGray-700 text-xl font-bold">{{ $t('share_code') }}</h6>
            </div>
          </div>
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form>
              <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                {{ $t('user_info') }}
              </h6>
              <div v-if="emailToSendCode" class="flex flex-wrap">
                <div class="w-full px-4">
                  <div class="relative w-full mb-3">
                    <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                      Email
                    </label>
                    <input
                        type="email"
                        placeholder="Email"
                        v-model="email"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
              </div>
              <div v-else class="flex flex-wrap">
                <div class="w-full px-4">
                  <div class="relative w-full mb-3">
                    <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                      Email
                    </label>
                    <input
                        type="email"
                        placeholder="Email"
                        v-model="email"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        disabled
                    />
                  </div>
                </div>
              </div>
              <div class="w-full w-full px-4">
                <div class="relative w-full mb-3">
                  <label class="inline-flex items-center cursor-pointer">
                    <input id="checkPublic" type="checkbox"
                           class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                           @change="showEmail">
                    <span class="ml-2 text-sm font-semibold text-blueGray-600">{{ $t('send_to_me') }}</span>
                  </label>
                </div>
              </div>
              <div
                  class="w-full flex items-center justify-center p-6 border-t border-solid border-blueGray-200 rounded-b py-3">
                <button
                    class="bg-yellow-400 text-white ative:bg-yellow-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button" v-on:click="toggleModal">
                  {{ $t('close') }}
                </button>
                <button
                    class="bg-yellow-400 text-white ative:bg-yellow-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button" v-on:click="formSubmit">
                  {{ $t('send') }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AuthService from "../../../services/auth.service";

export default {
  data() {
    return {
      email: '',
      emailToSendCode: true,
      AuthService
    };
  },

  methods: {
    toggleModal: function () {
      this.$parent.toggleShareCode();
    },

    showEmail() {
      this.emailToSendCode = !this.emailToSendCode;
    },

    validateEmail(email) {
      let re = /\S+@\S+\.\S+/;
      return re.test(email);
    },

    formSubmit(e) {
      e.preventDefault();
      if (this.emailToSendCode) {
        if (this.validateEmail(this.email)) {
          // condivide il codice con l'email inserita
          AuthService.emailUserForCode(this.$props.code, this.email).then(
              response => {
                if (response.data.status === "success") {
                  this.$fire({
                    title: this.$t('success'),
                    text: this.$t('code_sent_succ'),
                    type: "success",
                    confirmButtonColor: "#fbbf24",
                    timer: 3000
                  });
                } else {
                  this.$fire({
                    title: this.$t('error'),
                    html: this.$t('error_occurred') + ".<br>(" + response.data.message + ")",
                    type: "error",
                    confirmButtonColor: "#fbbf24",
                    timer: 3000
                  });
                }
              });
          if (this.$props.code2 !== '') {
            // se è un codice di reso condivide anche quello da dare al corriere
            AuthService.emailUserForCode(this.$props.code2, this.email).then(
                response => {
                  if (response.data.status === "success") {
                    this.$fire({
                      title: this.$t('success'),
                      text: this.$t('code_sent_succ'),
                      type: "success",
                      confirmButtonColor: "#fbbf24",
                      timer: 3000
                    });
                  } else {
                    this.$fire({
                      title: this.$t('error'),
                      html: this.$t('error_occurred') + ".<br>(" + response.data.message + ")",
                      type: "error",
                      confirmButtonColor: "#fbbf24",
                      timer: 3000
                    });
                  }
                });
          }
        } else {
          this.$fire({
            title: this.$t('error'),
            text: this.$t('insert_valid_email'),
            type: "error",
            confirmButtonColor: "#fbbf24",
            timer: 3000
          });
        }
      } else {
        AuthService.emailUserForCode(this.$props.code, false).then(
            response => {
              // condivide il codice alla email dell'utente
              if (response.data.status === "success") {
                this.$fire({
                  title: this.$t('success'),
                  text: this.$t('code_sent_succ'),
                  type: "success",
                  confirmButtonColor: "#fbbf24",
                  timer: 3000
                });
              } else {
                this.$fire({
                  title: this.$t('error'),
                  html: this.$t('error_occurred') + ".<br>(" + response.data.message + ")",
                  type: "error",
                  confirmButtonColor: "#fbbf24",
                  timer: 3000
                });
              }
            });
        if (this.$props.code2 !== '') {
          AuthService.emailUserForCode(this.$props.code2, false).then(
              response => {
                if (response.data.status === "success") {
                  this.$fire({
                    title: this.$t('success'),
                    text: this.$t('code_sent_succ'),
                    type: "success",
                    confirmButtonColor: "#fbbf24",
                    timer: 3000
                  });
                } else {
                  this.$fire({
                    title: this.$t('error'),
                    html: this.$t('error_occurred') + ".<br>(" + response.data.message + ")",
                    type: "error",
                    confirmButtonColor: "#fbbf24",
                    timer: 3000
                  });
                }
              });
        }
      }

      this.toggleModal();
    }
  },

  props: {
    code: String,
    code2: String,
  }
}
</script>
