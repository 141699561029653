<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div
            class="relative flex flex-col min-w-0 break-words w-full rounded-lg bg-blueGray-100 border-0"
        >
          <div class="rounded-t bg-white mb-0 px-6 py-6">
            <div class="text-center flex justify-between">
              <h6 class="text-blueGray-700 text-xl font-bold">QR Code</h6>
            </div>
          </div>
          <div class="w-full">
            <ul v-if="this.$parent.showQrcode2" class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
              <li class="-mb-px mr-2 last:mr-0 flex-auto text-center w-full-m">
                <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg block leading-normal"
                   v-on:click="toggleTabs(1)"
                   v-bind:class="{'text-yellow-400 bg-white': openTab !== 1, 'text-white bg-yellow-400': openTab === 1}">
                  <i class="fas fa-box mr-2 text-sm"></i>
                  {{ $tc('deposit_code', 1) }}
                </a>
              </li>
              <li class="-mb-px mr-2 last:mr-0 flex-auto text-center w-full-m">
                <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg block leading-normal"
                   v-on:click="toggleTabs(2)"
                   v-bind:class="{'text-yellow-400 bg-white': openTab !== 2, 'text-white bg-yellow-400': openTab === 2}">
                  <i class="fas fa-receipt mr-2 text-sm"></i>
                  {{ $t('courier_code') }}
                </a>
              </li>
            </ul>
            <div class="tab-content tab-space">
              <div v-bind:class="{'hidden': openTab !== 1, 'block': openTab === 1}">
                <div class="flex flex-wrap justify-center">
                  <div class="w-full px-4 text-center">
                    <div v-if="this.$parent.showQrcode" class="pt-6 pb-6">
                      <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                        {{ $tc('deposit_code', 1) }}
                      </h6>
                      <div class="w-full flex justify-center px-4">
                        <qrcode-vue :value="qr_value" :size="200" level="H"/>
                      </div>
                      <div class="w-full flex justify-center py-4 px-4">
                        <div class="relative mb-3">
                          <input
                              v-model="code"
                              type="text"
                              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="this.$parent.showQrcode2" v-bind:class="{'hidden': openTab !== 2, 'block': openTab === 2}">
                <div class="flex flex-wrap justify-center">
                  <div class="w-full px-4 text-center">
                    <div class="pb-6 pt-6">
                      <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                        {{ $t('courier_code') }}
                      </h6>
                      <div class="w-full flex justify-center px-4">
                        <qrcode-vue :value="qr_value2" :size="200" level="H"/>
                      </div>
                      <div class="w-full flex justify-center py-4 px-4">
                        <div class="relative mb-3">
                          <input
                              v-model="code2"
                              type="text"
                              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                  class="w-full flex items-center justify-center p-6 border-t border-solid border-blueGray-200 rounded-b py-3">
                <button
                    class="bg-yellow-400 text-white ative:bg-yellow-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button" v-on:click="toggleModal">
                  {{ $t('close') }}
                </button>
                <button
                    class="bg-yellow-400 text-white ative:bg-yellow-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button" v-on:click="toggleShareCode">
                  {{ $t('share') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import QrcodeVue from "qrcode.vue";

export default {
  data() {
    return {
      code: this.$props.qr_value,
      code2: this.$props.qr_value2,
      openTab: 1,
    };
  },

  methods: {
    toggleModal: function () {
      this.$parent.toggleQrcode();
    },

    toggleShareCode: function () {
      if(this.openTab === 1)
        this.$parent.setCode(this.code,'');
      else
        this.$parent.setCode(this.code2, '');
      this.$parent.toggleShareCode();
    },

    toggleTabs: function (tabNumber) {
      this.openTab = tabNumber;
      this.newCode = false;
    },
  },

  components: {
    QrcodeVue,
  },

  props: {
    qr_value: String,
    qr_value2: String,
  }
}
</script>
