<template>
  <!-- Header -->
  <div class="relative bg-yellow-300 md:pt-32 pb-16 pt-12">
    <div class="px-4 md:px-10 mx-auto w-full">
      <div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
};
</script>
