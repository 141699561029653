<template>
  <div></div>
</template>
<script>
import AuthService from "../services/auth.service";

export default {
  data() {
    return {
      AuthService,
    };
  },

  mounted() {
    AuthService.activateUser(this.$route.params.userId, this.$route.params.token).then(
        response => {
          if (response.data.status === "success") {
            this.$router.push('/login/');
            this.$fire({
              title: this.$t('success'),
              text: this.$t('confirm_email_succ'),
              type: "success",
              confirmButtonColor: "#fbbf24",
              timer: 3000
            });
          } else {
            this.$fire({
              title: this.$t('error'),
              html: this.$t('error_occurred') + ".<br>(" + response.data.message + ")",
              type: "error",
              confirmButtonColor: "#fbbf24",
              timer: 3000
            });
          }
        })
  }
};
</script>
