<template>
  <div>
    <a
        class="text-blueGray-500 py-1 px-3"
        href="#pablo"
        ref="btnDropdownRef"
        v-on:click="toggleDropdown($event)"
    >
      <i class="fas fa-ellipsis-v"></i>
    </a>
    <div
        ref="popoverDropdownRef"
        class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
        v-click-outside="hideDropdown"
    >
      <a
          @click="editRoles"
          href="javascript:void(0);"
          class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        {{ $t('manage_roles') }}
      </a>
    </div>
  </div>
</template>
<script>
import { createPopper } from "@popperjs/core";
import AuthService from "../../../services/auth.service";

export default {
  data() {
    return {
      dropdownPopoverShow: false,
      dropdownClicksCounter: 0,
      AuthService
    };
  },

  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },

    hideDropdown: function () {
      this.dropdownClicksCounter++;
      if(this.dropdownClicksCounter === 2) {
        this.dropdownClicksCounter = 0;
        if(this.dropdownPopoverShow)
          this.dropdownPopoverShow = !this.dropdownPopoverShow;
      }
    },

    editRoles: function () {
      this.$parent.setIndex(this.$props.index);
      this.$parent.toggleEditRoles();
    }
  },

  props: {
    index: Number,
  }
};
</script>
