<template>
  <div class="flex flex-wrap mt-4 min-height-view">
    <div class="w-full px-4">
      <card-edit-profile/>
    </div>
  </div>
</template>
<script>
import CardEditProfile from "@/components/Cards/Superuser/CardEditProfile.vue";

export default {
  components: {
    CardEditProfile,
  },
};
</script>
