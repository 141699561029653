<template>
  <div class="flex flex-wrap mt-4 min-height-view">
    <div class="w-full mb-12 px-4">
      <card-packages/>
    </div>
  </div>
</template>
<script>
import CardPackages from "@/components/Cards/User/CardPackages.vue";

export default {
  components: {
    CardPackages,
  },
};
</script>
