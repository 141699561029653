<template>
  <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
      :class="[color === 'light' ? 'bg-white' : 'bg-yellow-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="px-3 text-center flex justify-between">
        <h3
            class="font-semibold text-lg"
            :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
        >
          {{ $t('operations_history') }}
        </h3>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
        <tr>
          <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-yellow-800 text-yellow-300 border-emerald-700',
              ]"
          >
            Locker
          </th>
          <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-yellow-800 text-yellow-300 border-emerald-700',
              ]"
          >
            {{ $t('operation_type') }}
          </th>
          <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left d-none"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-yellow-800 text-yellow-300 border-emerald-700',
              ]"
          >
            {{ $t('operation_date') }}
          </th>
        </tr>
        </thead>
        <tbody>
        <template v-for="log in logs">
          <tr v-if="log.id > 0" :key="log.id">
            <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {{ getLockerById(log.locker_id) }}
            </td>
            <td v-if="reformat(log.operation_type) === 'Deposited'"
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            ><i class="fas fa-arrow-down mr-2 text-sm"></i>
              {{ $t('deposit') + log.operation_type.substr(18, 29).substr(0, 11) }}
            </td>
            <td v-else
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            ><i class="fas fa-arrow-up mr-2 text-sm"></i>
              {{ $t('withdrawn') + log.operation_type.substr(18, 29).substr(0, 11) }}
            </td>
            <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 d-none"
            >
              {{ log.created_at.replace(/[TZ]/g, ' ').substr(0, 19) }}
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import AuthService from "../../../services/auth.service";

export default {
  name: 'CardHistory',
  data() {
    return {
      logs: [],
      lockers: [],
      AuthService
    };
  },

  mounted: function () {
    this.getLockerList();
    this.getLogs();
    window.setInterval(() => {
      this.getLockerList();
      this.getLogs();
    }, 20000)
  },

  methods: {
    getLogs: async function () {
      let logs = [];
      let currentUser = await AuthService.getCurrentUser();
      logs = await AuthService.getLogsByUserId(currentUser.data.message.id);
      this.logs = logs.data.message.reverse();
    },

    getLockerList: async function () {
      let lockerList = [];
      let currentUser = await AuthService.getCurrentUser();
      lockerList = await AuthService.getLockersOfUser(currentUser.data.message.id);
      this.lockers = lockerList.data.message;
    },

    getLockerById(id) {
      for(let locker of this.lockers) {
        if (locker.id === id)
          return locker.name;
      }
    },

    reformat: function (operationType) {
      if(operationType.includes("Deposited"))
        return "Deposited";
      else
        return "Withdrawn";
    }
  },

  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
