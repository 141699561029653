<template>
  <div>
    <locker-manager-sidebar/>
    <div class="relative md:ml-64 bg-blueGray-100">
      <locker-manager-navbar/>
      <header-dashboard />
      <div class="px-4 h-full md:px-10 mx-auto w-full -m-24 min-height-layout">
        <router-view/>
        <footer-small/>
      </div>
    </div>
  </div>
</template>
<script>
import LockerManagerNavbar from "@/components/Navbars/LockerManagerNavbar.vue";
import LockerManagerSidebar from "@/components/Sidebar/LockerManagerSidebar.vue";
import FooterSmall from "@/components/Footers/FooterSmall.vue";
import HeaderDashboard from "../components/Headers/HeaderDashboard";
import AuthService from "../services/auth.service";
export default {
  name: "manager-layout",

  async created() {
    const token = await AuthService.getAuthToken();
    if(token.data.message !== "manager")
      await this.$router.push('/');
  },

  components: {
    HeaderDashboard,
    LockerManagerNavbar,
    LockerManagerSidebar,
    FooterSmall,
  },
};
</script>
