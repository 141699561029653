<template>
  <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
      :class="[color === 'light' ? 'bg-white' : 'bg-yellow-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="px-3 text-center flex">
        <button
            class="bg-yellow-400 text-white ative:bg-yellow-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-4 ease-linear transition-all duration-150"
            type="button"
            v-on:click="undoToLockers()"
        >
          <i class="fas fa-arrow-left text-sm mx-auto"></i>
        </button>
        <h3
            class="font-semibold text-lg"
            :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
        >
            {{ name }} - Info
        </h3>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
        <tr>
          <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-yellow-800 text-yellow-300 border-emerald-700',
              ]"
          >
            {{ $t('type') }}
          </th>
          <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-yellow-800 text-yellow-300 border-emerald-700',
              ]"
          >
            ID
          </th>
          <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-yellow-800 text-yellow-300 border-emerald-700',
              ]"
          >
            {{ $t('name') }}
          </th>
          <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-yellow-800 text-yellow-300 border-emerald-700',
              ]"
          >
            {{ $t('address') }}
          </th>
          <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-yellow-800 text-yellow-300 border-emerald-700',
              ]"
          >
            {{ $t('latitude') }}
          </th>
          <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-yellow-800 text-yellow-300 border-emerald-700',
              ]"
          >
            {{ $t('longitude') }}
          </th>
          <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-yellow-800 text-yellow-300 border-emerald-700',
              ]"
          >
            Password
          </th>
          <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-yellow-800 text-yellow-300 border-emerald-700',
              ]"
          >
            {{ $t('creation_date') }}
          </th>
          <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-yellow-800 text-yellow-300 border-emerald-700',
              ]"
          ></th>
        </tr>
        </thead>
        <tbody>
          <tr>
            <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <div v-if="is_public === 0">
                {{ $t('private') }}
              </div>
              <div v-else-if="is_public === 1">
                {{ $t('public') }}
              </div>
              <div v-else>
                -
              </div>
            </td>
            <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <div v-if="id === ''">-</div>
              <div v-else>{{ id }}</div>
            </td>
            <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <div v-if="name === ''">-</div>
              <div v-else>{{ name }}</div>
            </td>
            <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <div v-if="address=== ''">-</div>
              <div v-else>{{ address }}</div>
            </td>
            <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <div v-if="latitude === ''">-</div>
              <div v-else>{{ latitude }}</div>
            </td>
            <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <div v-if="longitude === ''">-</div>
              <div v-else>{{ longitude }}</div>
            </td>
            <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <div v-if="password === ''">-</div>
              <div v-else>{{ password }}</div>
            </td>
            <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {{ created_at.replace(/[TZ]/g, ' ').substr(0, 19) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import AuthService from "../../../services/auth.service";

export default {
  name: 'Locker',
  data() {
    return {
      locker: '',
      id: '',
      name: '',
      address: '',
      latitude: '',
      longitude: '',
      is_public: '',
      password: '',
      created_at: '',
      AuthService
    };
  },

  mounted() {
    this.locker = this.$parent.$props.locker;
    this.name = this.locker.name;
    this.id = this.locker.id;
    this.name = this.locker.name;
    this.address = this.locker.address;
    this.is_public = this.locker.is_public;
    this.latitude = this.locker.latitude;
    this.longitude = this.locker.longitude;
    this.password = this.locker.password;
    this.created_at = this.locker.created_at;
  },

  methods: {
    undoToLockers() {
      this.$parent.showLockerData = false;
    }
  },

  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
