<template>
  <div class="flex flex-wrap mt-4 min-height-view">
    <div class="w-full mb-12 px-4" v-if="!showLockerData">
      <card-lockers />
    </div>
    <div class="w-full mb-12 px-4" v-if="showLockerData">
      <card-locker-info />
    </div>
    <div class="w-full mb-12 px-4" v-if="showLockerData">
      <card-locker-drawers />
    </div>
    <div class="w-full mb-12 px-4" v-if="showLockerData">
      <card-managers-of-locker />
    </div>
    <div class="w-full mb-12 px-4" v-if="showLockerData">
      <card-locker-users />
    </div>
    <div class="w-full mb-12 px-4" v-if="showLockerData">
      <card-locker-awaiting-users />
    </div>
  </div>
</template>
<script>
import CardLockers from "@/components/Cards/Superuser/CardLockers.vue";
import CardLockerInfo from "@/components/Cards/Superuser/CardLockerInfo.vue";
import CardLockerDrawers from "@/components/Cards/Superuser/CardLockerDrawers.vue";
import CardManagersOfLocker from "@/components/Cards/Superuser/CardManagersOfLocker.vue";
import CardLockerUsers from "@/components/Cards/Superuser/CardLockerUsers.vue";
import CardLockerAwaitingUsers from "@/components/Cards/Superuser/CardLockerAwaitingUsers.vue";

export default {
  data() {
    return {
      showLockerData: false,
    };
  },

  components: {
    CardLockers,
    CardLockerInfo,
    CardLockerDrawers,
    CardLockerUsers,
    CardManagersOfLocker,
    CardLockerAwaitingUsers
  },

  props: {
    locker: Object,
  },
};
</script>
