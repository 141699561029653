<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div
            class="relative flex flex-col min-w-0 break-words w-full rounded-lg bg-blueGray-100 border-0"
        >
          <div class="rounded-t bg-white mb-0 px-6 py-6">
            <div class="text-center flex justify-between">
              <h6 class="text-blueGray-700 text-xl font-bold">{{ $t('edit_manager') }}</h6>
            </div>
          </div>
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form>
              <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                {{ $t('manager_info') }}
              </h6>
              <div class="flex flex-wrap">
                <div class="w-full lg:w-6/12 px-4">
                  <div class="relative w-full mb-3">
                    <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                      Username
                    </label>
                    <input
                        type="text"
                        placeholder="Username"
                        v-model="username"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
                <div class="w-full lg:w-6/12 px-4">
                  <div class="relative w-full mb-3">
                    <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                      Email
                    </label>
                    <input
                        type="email"
                        placeholder="Email"
                        v-model="email"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
                <div class="w-full lg:w-6/12 px-4">
                  <div class="relative w-full mb-3">
                    <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                      Password
                    </label>
                    <input
                        type="password"
                        placeholder="Password"
                        v-model="password"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
                <div class="w-full lg:w-6/12 px-4">
                  <div class="relative w-full mb-3">
                    <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                      {{ $t('name') }}
                    </label>
                    <input
                        type="text"
                        :placeholder="$t('name')"
                        v-model="name"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
                <div class="w-full lg:w-6/12 px-4">
                  <div class="relative w-full mb-3">
                    <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                      {{ $t('surname') }}
                    </label>
                    <input
                        type="text"
                        :placeholder="$t('surname')"
                        v-model="surname"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
                <div class="w-full flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b py-3">
                  <button class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          v-on:click="toggleModal">
                    {{ $t('close') }}
                  </button>
                  <button class="bg-yellow-400 text-white ative:bg-yellow-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          v-on:click="formSubmit">
                    {{ $t('save_changes') }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AuthService from "../../../services/auth.service";

export default {
  data() {
    return {
      username: '',
      email: '',
      password: '',
      name: '',
      surname: '',
      AuthService
    };
  },

  mounted() {
    let manager = this.$parent.managers[this.$props.index];
    this.username = manager.username;
    this.email = manager.email;
    this.name = manager.name;
    this.surname = manager.surname;
  },

  methods: {
    toggleModal: function(){
      this.$parent.toggleEditManager();
    },

    validateEmail(email) {
      let re = /\S+@\S+\.\S+/;
      return re.test(email);
    },

    formSubmit: function() {
      let manager = this.$parent.managers[this.$props.index];
      if(this.username !== '' || this.email !== '' || this.name !== '' || this.surname !== '') {
        if(this.validateEmail(this.email)) {
          // Modifica il manager se tutti i campi sono pieni, inclusa la password
          if (this.password !== '') {
            AuthService.editManager(manager.id, this.username, this.email, this.password, this.name, this.surname, manager.status).then(
                response => {
                  if (response.data.status === "success") {
                    // aggiorna i manager in caso di successo
                    AuthService.getManagers().then(
                        response => {
                          this.$parent.managers = response.data.message;
                        }
                    );
                    this.$fire({
                      title: this.$t('success'),
                      text: this.$t('manager_edited_succ'),
                      type: "success",
                      confirmButtonColor: "#fbbf24",
                      timer: 3000
                    });
                  } else {
                    this.$fire({
                      title: this.$t('error'),
                      html: this.$t('error_occurred') + ".<br>(" + response.data.message + ")",
                      type: "error",
                      confirmButtonColor: "#fbbf24",
                      timer: 3000
                    });
                  }
                }
            );
          } else {
            // Modifica il manager se tutti i campi sono pieni, tranne la password (quest'ultima non viene modifica)
            AuthService.editManager(manager.id, this.username, this.email, this.password, this.name, this.surname, manager.status).then(
                response => {
                  if (response.data.status === "success") {
                    // aggiorna i manager in caso di successo
                    AuthService.getManagers().then(
                        response => {
                          this.$parent.managers = response.data.message;
                        }
                    );
                    this.$fire({
                      title: this.$t('success'),
                      text: this.$t('manager_edited_succ'),
                      type: "success",
                      confirmButtonColor: "#fbbf24",
                      timer: 3000
                    });
                  } else {
                    this.$fire({
                      title: this.$t('error'),
                      html: this.$t('error_occurred') + ".<br>(" + response.data.message + ")",
                      type: "error",
                      confirmButtonColor: "#fbbf24",
                      timer: 3000
                    });
                  }
                }
            );
          }
        } else {
          this.$fire({
            title: this.$t('error'),
            text: this.$t('insert_valid_email'),
            type: "error",
            confirmButtonColor: "#fbbf24",
            timer: 3000
          });
        }
      } else {
        this.$fire({
          title: this.$t('error'),
          text: this.$t('all_fields_full_ex_pass'),
          type: "error",
          confirmButtonColor: "#fbbf24",
          timer: 3000
        });
      }
      this.toggleModal();
    }
  },

  props: {
    index: Number,
  }
};
</script>
