<template>
  <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
      :class="[color === 'light' ? 'bg-white' : 'bg-yellow-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="px-3 text-center flex justify-between">
        <h3
            class="font-semibold text-lg"
            :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
        >
          {{ $tc('code', 2) }}
        </h3>
      </div>
    </div>
    <div class="flex flex-wrap justify-center">
      <div class="w-full lg:w-6/12 px-4 pb-4">
        <div class="relative w-full mb-3 text-center-m">
          <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            {{ $t('your')}} locker {{ $t('is')}}
          </label>
          <select
              v-model="lockerId"
              type="text"
              @change="checkMasterCode"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          >
            <option value="" disabled>{{ $t('select')}} locker</option>
            <option v-for="locker in lockers" :key="locker.id" :value="locker.id" >
              {{ locker.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <card-share-code v-if="showShareCode" v-bind:code="codeToShare" v-bind:code2="code2ToShare"/>
    <card-qrcode v-if="showQrcode" v-bind:qr_value="qrcode" v-bind:qr_value2="qrcode2"/>
    <div v-if="lockerId !== ''" class="flex flex-wrap">
      <div class="w-full">
        <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
          <li class="-mb-px mr-2 last:mr-0 flex-auto text-center w-full-m lg:w-3/12">
            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg block leading-normal" v-on:click="toggleTabs(1)"
               v-bind:class="{'text-yellow-400 bg-white': openTab !== 1, 'text-white bg-yellow-400': openTab === 1}">
              <i class="fas fa-box mr-2 text-sm"></i>
              {{ $tc('deposit_code', 2) }}
            </a>
          </li>
          <li class="-mb-px mr-2 last:mr-0 flex-auto text-center w-full-m lg:w-3/12">
            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg block leading-normal" v-on:click="toggleTabs(2)"
               v-bind:class="{'text-yellow-400 bg-white': openTab !== 2, 'text-white bg-yellow-400': openTab === 2}">
              <i class="fas fa-receipt mr-2 text-sm"></i>
              {{ $tc('deposit_code_with_recipient', 2) }}
            </a>
          </li>
          <li class="-mb-px mr-2 last:mr-0 flex-auto text-center w-full-m lg:w-3/12">
            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg block leading-normal" v-on:click="toggleTabs(3)"
               v-bind:class="{'text-yellow-400 bg-white': openTab !== 3, 'text-white bg-yellow-400': openTab === 3}">
              <i class="fas fa-undo mr-2 text-sm"></i>
              {{ $tc('return_code', 2) }}
            </a>
          </li>
        </ul>
        <div class="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded">
          <div class="flex-auto">
            <div class="tab-content tab-space">
              <div v-bind:class="{'hidden': openTab !== 1, 'block': openTab === 1}">
                <div class="block w-full overflow-x-auto">
                  <div class="rounded-t bg-white mb-0 px-6 py-2">
                    <div class="text-center flex justify-between">
                      <h6 class="text-blueGray-700 text-xl font-bold">{{ $t('generate_new_dep') }}</h6>
                    </div>
                  </div>
                  <div class="flex-auto px-4 lg:px-10 pt-0 bg-blueGray-100 pb-4">
                    <form>
                      <h6 class="text-blueGray-400 text-sm pt-2 mt-3 mb-6 font-bold uppercase">
                        {{ $t('deposit_information') }}
                      </h6>
                      <div class="flex flex-wrap">
                        <div class="w-full lg:w-3/12 px-4 text-center-m">
                          <div class="relative w-full mb-3">
                            <button
                                class="bg-yellow-400 text-white active:bg-yellow-500 font-bold uppercase text-base px-3 py-3 rounded shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button" v-on:click="generateDepositCode" :disabled="depButtonIsDisabled">
                              <div class="flex flex-wrap justify-center"><img :src="store" width="60%"/></div>
                              <div class="font-semibold">{{ $t('generate') }}</div>
                            </button>
                          </div>
                        </div>
                        <div class="w-full lg:w-3/12 px-4 text-center-m mt-2">
                          <div class="relative w-full mb-3">
                            <label
                                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            >
                              {{ $t('friendly_name') }}
                            </label>
                            <input
                                v-model="friendlyName"
                                type="text"
                                :placeholder="$t('friendly_name')"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            />
                          </div>
                        </div>
                        <div class="w-full lg:w-4/12 px-4 text-center-m">
                          <div class="relative w-full mb-3 mt-8">
                            <label class="inline-flex items-center cursor-pointer">
                              <input v-if="this.existsMasterCode" type="checkbox"
                                     class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                     @change="isOneTime = !isOneTime"
                                     :checked="false"
                                     disabled
                              >
                              <input v-else type="checkbox"
                                     class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                     @change="isOneTime = !isOneTime"
                              >
                              <span v-if="this.existsMasterCode" class="ml-2 text-sm font-semibold text-blueGray-400">{{ $t('master_code') }}</span>
                              <span v-else class="ml-2 text-sm font-semibold text-blueGray-600">{{ $t('master_code') }}</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <!-- Projects table -->
                  <table class="items-center w-full bg-transparent border-collapse">
                    <thead>
                    <tr>
                      <th
                          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-yellow-800 text-yellow-300 border-emerald-700',
                            ]"
                      >
                        {{ $tc('code', 1) }}
                      </th>
                      <th
                          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-yellow-800 text-yellow-300 border-emerald-700',
                            ]"
                      >
                        {{ $t('type') }}
                      </th>
                      <th
                          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left d-none"
                          :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-yellow-800 text-yellow-300 border-emerald-700',
                            ]"
                      >
                        {{ $t('friendly_name') }}
                      </th>
                      <th
                          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left d-none"
                          :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-yellow-800 text-yellow-300 border-emerald-700',
                            ]"
                      >
                        {{ $t('creation_date') }}
                      </th>
                      <th
                          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-yellow-800 text-yellow-300 border-emerald-700',
                            ]"
                      >
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="(code, index) in depCodes">
                      <tr v-if="code.id > 0 && !code.one_time && code.locker_id === lockerId" :key="code.id">
                        <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-lg whitespace-nowrap p-4 font-bold"
                        >
                          <div v-if="code.code === ''">-</div>
                          <div v-else>{{ '001' + code.code }}</div>
                        </td>
                        <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-lg whitespace-nowrap p-4 font-bold"
                        >
                          <div v-if="code.code === ''">-</div>
                          <div v-else>{{ $t('master') }}</div>
                        </td>
                        <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-lg whitespace-nowrap p-4 font-bold d-none"
                        >
                          <div v-if="code.friendly_name === ''">-</div>
                          <div v-else>{{ code.friendly_name }}</div>
                        </td>
                        <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-lg whitespace-nowrap p-4 font-bold d-none"
                        >
                          <div v-if="code.code.created_at === ''">-</div>
                          <div v-else>{{ code.created_at.replace(/[TZ]/g, ' ').substr(0, 19) }}</div>
                        </td>
                        <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
                        >
                          <dep-dropdown v-bind:index="index"/>
                        </td>
                      </tr>
                    </template>
                    <template v-for="(code, index) in depCodes">
                      <tr v-if="code.id > 0 && code.one_time && code.status !== 0 && code.locker_id === lockerId" :key="code.id">
                        <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                        >
                          <div v-if="code.code === ''">-</div>
                          <div v-else>{{ '001' + code.code }}</div>
                        </td>
                        <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                        >
                          <div v-if="code.code === ''">-</div>
                          <div v-else>{{ $t('one_time') }}</div>
                        </td>
                        <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 d-none"
                        >
                          <div v-if="code.friendly_name === ''">-</div>
                          <div v-else>{{ code.friendly_name }}</div>
                        </td>
                        <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 d-none"
                        >
                          <div v-if="code.code.created_at === ''">-</div>
                          <div v-else>{{ code.created_at.replace(/[TZ]/g, ' ').substr(0, 19) }}</div>
                        </td>
                        <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
                        >
                          <dep-dropdown v-bind:index="index"/>
                        </td>
                      </tr>
                    </template>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-bind:class="{'hidden': openTab !== 2, 'block': openTab === 2}">
                <div class="block w-full overflow-x-auto">
                  <div class="rounded-t bg-white mb-0 px-6 py-2">
                    <div class="text-center flex justify-between">
                      <h6 class="text-blueGray-700 text-xl font-bold">{{ $t('generate_new_der') }}</h6>
                    </div>
                  </div>
                  <div class="flex-auto px-4 lg:px-10 pt-0 bg-blueGray-100 pb-4">
                    <form>
                      <h6 class="text-blueGray-400 text-sm pt-2 mt-3 mb-6 font-bold uppercase">
                        {{ $t('deposit_information') }}
                      </h6>
                      <div class="flex flex-wrap">
                        <div class="w-full lg:w-3/12 px-4 text-center-m">
                          <div class="relative w-full mb-3">
                            <button
                                class="bg-yellow-400 text-white active:bg-yellow-500 font-bold uppercase text-base px-3 py-3 rounded shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button" v-on:click="generateDepositCodeWithRecipient" :disabled="derButtonIsDisabled">
                              <div class="flex flex-wrap justify-center"><img :src="storeRecipient" width="60%"/></div>
                              <div class="font-semibold">{{ $t('generate') }}</div>
                            </button>

                          </div>
                        </div>
                        <div class="w-full lg:w-3/12 px-4 text-center-m mt-2">
                          <div class="relative w-full mb-3">
                            <label
                                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            >
                              {{ $t('friendly_name') }}
                            </label>
                            <input
                                v-model="friendlyName"
                                type="text"
                                :placeholder="$t('friendly_name')"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            />
                          </div>
                        </div>
                        <div class="w-full lg:w-3/12 px-4 text-center-m mt-2">
                          <div class="relative w-full mb-3">
                            <label
                                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            >
                              {{ $t('recipient_name') }}
                            </label>
                            <input
                                v-model="recipientName"
                                type="text"
                                :placeholder="$t('recipient_name')"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            />
                          </div>
                        </div>
                        <div class="w-full lg:w-3/12 px-4 text-center-m mt-2">
                          <div class="relative w-full mb-3">
                            <label
                                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            >
                              {{ $t('recipient_email') }}
                            </label>
                            <input
                                v-model="recipientEmail"
                                type="email"
                                :placeholder="$t('recipient_email')"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <!-- Projects table -->
                  <table class="items-center w-full bg-transparent border-collapse">
                    <thead>
                    <tr>
                      <th
                          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-yellow-800 text-yellow-300 border-emerald-700',
                            ]"
                      >
                        {{ $tc('code', 1) }}
                      </th>
                      <th
                          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left d-none"
                          :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-yellow-800 text-yellow-300 border-emerald-700',
                            ]"
                      >
                        {{ $t('friendly_name') }}
                      </th>
                      <th
                          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left d-none"
                          :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-yellow-800 text-yellow-300 border-emerald-700',
                            ]"
                      >
                        {{ $t('recipient_name') }}
                      </th>
                      <th
                          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-yellow-800 text-yellow-300 border-emerald-700',
                            ]"
                      >
                        {{ $t('rec_email') }}
                      </th>
                      <th
                          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left d-none"
                          :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-yellow-800 text-yellow-300 border-emerald-700',
                            ]"
                      >
                        {{ $t('creation_date') }}
                      </th>
                      <th
                          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-yellow-800 text-yellow-300 border-emerald-700',
                            ]"
                      >
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="(code, index) in derCodes">
                      <tr v-if="code.id > 0 && code.status !== 0 && code.locker_id === lockerId" :key="code.id">
                        <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                        >
                          <div v-if="code.code === ''">-</div>
                          <div v-else>{{ '002' + code.code }}</div>
                        </td>
                        <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 d-none"
                        >
                          <div v-if="code.friendly_name === ''">-</div>
                          <div v-else>{{ code.friendly_name }}</div>
                        </td>
                        <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 d-none"
                        >
                          <div v-if="code.recipient_name === ''">-</div>
                          <div v-else>{{ code.recipient_name }}</div>
                        </td>
                        <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                        >
                          <div v-if="code.recipient_email === ''">-</div>
                          <div v-else>{{ code.recipient_email }}</div>
                        </td>
                        <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 d-none"
                        >
                          <div v-if="code.code.created_at === ''">-</div>
                          <div v-else>{{ code.created_at.replace(/[TZ]/g, ' ').substr(0, 19) }}</div>
                        </td>
                        <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
                        >
                          <der-dropdown v-bind:index="index"/>
                        </td>
                      </tr>
                    </template>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-bind:class="{'hidden': openTab !== 3, 'block': openTab === 3}">
                <div class="block w-full overflow-x-auto">
                  <div class="rounded-t bg-white mb-0 px-6 py-2">
                    <div class="text-center flex justify-between">
                      <h6 class="text-blueGray-700 text-xl font-bold">{{ $t('generate_new_return_codes') }}</h6>
                    </div>
                  </div>
                  <div class="flex-auto px-4 lg:px-10 pt-0 bg-blueGray-100 pb-4">
                    <form>
                      <h6 class="text-blueGray-400 text-sm pt-2 mt-3 mb-6 font-bold uppercase">
                        {{ $t('return_information') }}
                      </h6>
                      <div class="flex flex-wrap">
                        <div class="w-full lg:w-3/12 px-4 text-center-m">
                          <div class="relative w-full mb-3">
                            <button
                                class="bg-yellow-400 text-white active:bg-yellow-500 font-bold uppercase text-base px-3 py-3 rounded shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button" v-on:click="generateReturnCodes" :disabled="drdButtonIsDisabled">
                              <div class="flex flex-wrap justify-center"><img :src="reTurn" width="60%"/></div>
                              <div class="font-semibold">{{ $t('generate') }}</div>
                            </button>
                          </div>
                        </div>
                        <div class="w-full lg:w-3/12 px-4 text-center-m mt-2">
                          <div class="relative w-full mb-3">
                            <label
                                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            >
                              {{ $t('friendly_name') }}
                            </label>
                            <input
                                v-model="friendlyName"
                                type="text"
                                :placeholder="$t('friendly_name')"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <!-- Projects table -->
                  <table class="items-center w-full bg-transparent border-collapse">
                    <thead>
                    <tr>
                      <th
                          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-yellow-800 text-yellow-300 border-emerald-700',
                            ]"
                      >
                        {{ $t('code_for_deposit') }}
                      </th>
                      <th
                          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-yellow-800 text-yellow-300 border-emerald-700',
                            ]"
                      >
                        {{ $t('code_for_courier') }}
                      </th>
                      <th
                          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left d-none"
                          :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-yellow-800 text-yellow-300 border-emerald-700',
                            ]"
                      >
                        {{ $t('friendly_name') }}
                      </th>
                      <th
                          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left d-none"
                          :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-yellow-800 text-yellow-300 border-emerald-700',
                            ]"
                      >
                        {{ $t('creation_date') }}
                      </th>
                      <th
                          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          :class="[
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-yellow-800 text-yellow-300 border-emerald-700',
                            ]"
                      >
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="(drd, index) in drdCodes">
                      <template v-for="drr in drrCodes">
                        <tr v-if="drr.deposit_id === drd.id && drr.locker_id === lockerId" :key="drd.id + drr.id">
                          <td
                              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            <div v-if="drd.code === ''">-</div>
                            <div v-else>{{ '003' + drd.code }}</div>
                          </td>
                          <td
                              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            <div v-if="drr.code === ''">-</div>
                            <div v-else>{{ '004' + drr.code }}</div>
                          </td>
                          <td
                              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 d-none"
                          >
                            <div v-if="drd.friendly_name === '' || drd.friendly_name === 'Unnamed'">-</div>
                            <div v-else>{{ drd.friendly_name }}</div>
                          </td>
                          <td
                              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 d-none"
                          >
                            <div v-if="drd.created_at === ''">-</div>
                            <div v-else>{{ drd.created_at.replace(/[TZ]/g, ' ').substr(0, 19) }}</div>
                          </td>
                          <td
                              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
                          >
                            <drd-dropdown v-bind:index="index"/>
                          </td>
                        </tr>
                      </template>
                    </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AuthService from "../../../services/auth.service";
import CardQrcode from "@/components/Cards/User/CardQrcode.vue";
import CardShareCode from "@/components/Cards/User/CardShareCode.vue";
import DepDropdown from "@/components/Dropdowns/User/DepDropdown.vue";
import DerDropdown from "@/components/Dropdowns/User/DerDropdown.vue";
import DrdDropdown from "@/components/Dropdowns/User/DrdDropdown.vue";
import store from "@/assets/img/dep.png";
import storeRecipient from "@/assets/img/der.png";
import reTurn from "@/assets/img/ddr.png";

export default {
  name: 'CardCodes',
  data() {
    return {
      store,
      storeRecipient,
      reTurn,
      openTab: 1,
      showQrcode: false,
      showQrcode2: false,
      showShareCode: false,
      existsMasterCode: false,
      depButtonIsDisabled: false,
      derButtonIsDisabled: false,
      drdButtonIsDisabled: false,
      lockers: [],
      depCodes: [],
      derCodes: [],
      drdCodes: [],
      drrCodes: [],
      codeToShare: '',
      code2ToShare: '',
      qrcode: '',
      qrcode2: '',
      lockerId: '',
      friendlyName: '',
      recipientName: '',
      recipientEmail: '',
      isOneTime: true,
      userId: '',
    };
  },

  mounted() {
    this.getLockerList();
    AuthService.readDepositCodes().then(
        response => {
          this.depCodes = response.data.message.reverse();
        }
    );

    AuthService.readDepositCodesWithRecipient().then(
        response => {
          this.derCodes = response.data.message.reverse();
        }
    );

    AuthService.readReturnDepositCodes().then(
        response => {
          this.drdCodes = response.data.message.reverse();
        }
    );

    AuthService.readReturnCourierCodes().then(
        response => {
          this.drrCodes = response.data.message.reverse();
        }
    );
  },

  methods: {
    toggleTabs: function (tabNumber) {
      this.openTab = tabNumber;
      this.newCode = false;
    },

    toggleQrcode: function () {
      this.showQrcode = !this.showQrcode;
      if (this.showShareCode) this.showShareCode = !this.showShareCode;
    },

    toggleShareCode: function () {
      this.showShareCode = !this.showShareCode;
      if (this.showQrcode) this.showQrcode = !this.showQrcode;
      if (this.showQrcode2) this.showQrcode2 = !this.showQrcode2;
    },

    getLockerList: async function () {
      let lockerList = [];
      let currentUser = await AuthService.getCurrentUser();
      this.userId = currentUser.data.message.id;
      lockerList = await AuthService.getLockersOfUser(currentUser.data.message.id);
      this.lockers = lockerList.data.message;
    },

    checkMasterCode() {
      for(let code of this.depCodes) {
        if(!code.one_time && code.locker_id === this.lockerId)
          this.existsMasterCode = true;
      }
    },

    validateEmail(email) {
      let re = /\S+@\S+\.\S+/;
      return re.test(email);
    },

    generateDepositCode() {
      this.depButtonIsDisabled = true;
      if (this.lockerId !== '') {
        if (!this.isOneTime) {
          // crea un nuovo codice di deposito permanente
          AuthService.createNewDepositCode(this.lockerId, this.friendlyName, false, false, '', '').then(
              response => {
                if (response.data.status === "success") {
                  this.existsMasterCode = true; // ora esiste un codice permanente
                  // aggiorna i codici
                  AuthService.readDepositCodes().then(
                      response => {
                        this.depCodes = response.data.message.reverse();
                      }
                  );
                  this.toggleTabs(1);
                  this.$fire({
                    title: this.$t('success'),
                    html: this.$t('master_code_is') + "<br><b>" + response.data.message + "</b>",
                    type: "success",
                    confirmButtonColor: "#fbbf24",
                  });
                  this.depButtonIsDisabled = false;
                } else {
                  this.$fire({
                    title: this.$t('error'),
                    html: this.$t('error_occurred') + ".<br>(" + response.data.message + ")",
                    type: "error",
                    confirmButtonColor: "#fbbf24",
                    timer: 3000
                  });
                  this.depButtonIsDisabled = false;
                }
              }
          );
        } else {
          // crea un codice di deposito one-time
          AuthService.createNewDepositCode(this.lockerId, this.friendlyName, true, false, '', '').then(
              response => {
                if (response.data.status === "success") {
                  // aggiorna i codici
                  AuthService.readDepositCodes().then(
                      response => {
                        this.depCodes = response.data.message.reverse();
                      }
                  );
                  this.toggleTabs(1);
                  this.$fire({
                    title: this.$t('success'),
                    html: this.$t('code_is') + "<br><b>" + response.data.message + "</b>",
                    type: "success",
                    confirmButtonColor: "#fbbf24",
                  });
                  this.depButtonIsDisabled = false;
                } else {
                  this.$fire({
                    title: this.$t('error'),
                    html: this.$t('error_occurred') + ".<br>(" + response.data.message + ")",
                    type: "error",
                    confirmButtonColor: "#fbbf24",
                    timer: 3000
                  });
                  this.depButtonIsDisabled = false;
                }
              }
          );
        }
      } else {
        this.$fire({
          title: this.$t('error'),
          text: this.$t('select_locker_corr'),
          type: "error",
          confirmButtonColor: "#fbbf24",
          timer: 3000
        });
        this.depButtonIsDisabled = false;
      }
    },

    generateDepositCodeWithRecipient() {
      this.derButtonIsDisabled = true;
      if (this.lockerId !== '') {
        if (this.recipientName !== '' && this.recipientEmail !== '') {
          if(this.validateEmail(this.recipientEmail)) {
            // crea un codice di deposito con ricevuta
            AuthService.createNewDepositCode(this.lockerId, this.friendlyName, true, true, this.recipientName, this.recipientEmail).then(
                response => {
                  if (response.data.status === "success") {
                    // aggiorna i codici di deposito con ricevuta
                    AuthService.readDepositCodesWithRecipient().then(
                        response => {
                          this.derCodes = response.data.message.reverse();
                        }
                    );
                    this.toggleTabs(2);
                    this.$fire({
                      title: this.$t('success'),
                      html: this.$t('code_is') + "<br><b>" + response.data.message + "</b>",
                      type: "success",
                      confirmButtonColor: "#fbbf24",
                    });
                    this.depButtonIsDisabled = false;
                  } else {
                    this.$fire({
                      title: this.$t('error'),
                      html: this.$t('error_occurred') + ".<br>(" + response.data.message + ")",
                      type: "error",
                      confirmButtonColor: "#fbbf24",
                      timer: 3000
                    });
                    this.derButtonIsDisabled = false;
                  }
                }
            );
          } else {
            this.$fire({
              title: this.$t('error'),
              text: this.$t('insert_valid_email'),
              type: "error",
              confirmButtonColor: "#fbbf24",
              timer: 3000
            });
            this.derButtonIsDisabled = false;
          }
        } else {
          this.$fire({
            title: this.$t('error'),
            text: this.$t('rec_fields_full'),
            type: "error",
            confirmButtonColor: "#fbbf24",
            timer: 3000
          });
          this.derButtonIsDisabled = false;
        }
      } else {
        this.$fire({
          title: this.$t('error'),
          text: this.$t('select_locker_corr'),
          type: "error",
          confirmButtonColor: "#fbbf24",
          timer: 3000
        });
        this.derButtonIsDisabled = false;
      }
    },

    generateReturnCodes() {
      this.drdButtonIsDisabled = true;
      if (this.lockerId !== '') {
        // genera codici di reso
        AuthService.createNewReturnCode(this.lockerId, this.friendlyName).then(
            response => {
              if (response.data.status === "success") {
                // aggiorna codici di reso
                AuthService.readReturnDepositCodes().then(
                    response => {
                      this.drdCodes = response.data.message.reverse();
                    }
                );
                AuthService.readReturnCourierCodes().then(
                    response => {
                      this.drrCodes = response.data.message.reverse();
                    }
                );
                this.toggleTabs(3);
                this.$fire({
                  title: this.$t('success'),
                  html: this.$t('ddr_code_is') + "<br><b>" + response.data.message.deposit + "</b>" +
                      "<br><br>" + this.$t('drr_code_is') + "<br><b>" + response.data.message.courier + "</b>",
                  type: "success",
                  confirmButtonColor: "#fbbf24",
                });
                this.drdButtonIsDisabled = false;
              } else {
                this.$fire({
                  title: this.$t('error'),
                  html: this.$t('error_occurred') + ".<br>(" + response.data.message + ")",
                  type: "error",
                  confirmButtonColor: "#fbbf24",
                  timer: 3000
                });
                this.drdButtonIsDisabled = false;
              }
            }
        );
      } else {
        this.$fire({
          title: this.$t('error'),
          text: this.$t('select_locker_corr'),
          type: "error",
          confirmButtonColor: "#fbbf24",
          timer: 3000
        });
        this.drdButtonIsDisabled = false;
      }
    },

    setCode(code, code2) {
      this.codeToShare = code;
      this.code2ToShare = code2;
    }
  },

  components: {
    CardQrcode,
    CardShareCode,
    DepDropdown,
    DerDropdown,
    DrdDropdown,
  },

  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
