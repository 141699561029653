<template>
  <section class="relative w-full h-full py-16 min-h-screen">
    <div class="absolute top-0 w-full h-full bg-yellow-300 bg-no-repeat bg-full"></div>
    <div class="container mx-auto px-4 h-full">
      <div class="flex content-center items-center justify-center h-full">
        <div class="w-full lg:w-4/12 px-4">
          <div
              class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
            <div class="rounded-t mb-0 px-6 py-6 text-center mx-auto font-bold text-3xl">
              <img class="mx-auto py-6" width="70%" alt="" :src="logo"/>
            </div>
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form @submit.prevent="handleLogin">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-gray-500 text-xs font-bold mb-2">
                    Email
                  </label>
                  <input
                      v-model="user.email"
                      type="email"
                      class="border-0 px-3 py-3 placeholder-emerald-300 text-gray-500 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      name="email"
                      placeholder="Email"
                  />
                </div>
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-gray-500 text-xs font-bold mb-2">
                    Password
                  </label>
                  <div class="flex justify-between">
                    <input
                        v-if="!showPassword"
                        v-model="user.password"
                        type="password"
                        class="border-0 px-3 py-3 placeholder-emerald-300 text-gray-500 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        name="password"
                        placeholder="Password"
                    />
                    <input
                        v-else
                        v-model="user.password"
                        type="text"
                        class="border-0 px-3 py-3 placeholder-emerald-300 text-gray-500 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        name="password"
                        placeholder="Password"
                    />
                    <button
                        class="bg-yellow-400 text-white active:bg-yellow-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear ml-1 transition-all duration-150"
                        type="button"
                        v-on:click="togglePassword()"
                    >
                      <i v-if="!showPassword" class="fas fa-eye-slash text-sm"></i>
                      <i v-else class="fas fa-eye text-sm"></i>
                    </button>
                  </div>
                </div>
                <div v-if="showUsers" class="container mx-auto">
                  <div class="flex flex-wrap font-xxs">
                    <div class="w-full flex-1">
                      <input type="radio" id="superuser" value="Superuser" v-model="picked">
                      <label class="uppercase text-gray-500 font-bold mb-2 ml-2"
                             for="superuser">{{ $tc('superuser', 1) }}</label>
                    </div>
                    <div class="w-full flex-1">
                      <input type="radio" id="manager" value="Manager" v-model="picked">
                      <label class="uppercase text-gray-500 font-bold mb-2 ml-2"
                             for="manager">Manager</label>
                    </div>
                    <div class="w-full flex-1">
                      <input type="radio" id="user" value="User" v-model="picked" checked>
                      <label class="uppercase text-gray-500 font-bold mb-2 ml-2"
                             for="superuser">{{ $tc('user', 1) }}</label>
                    </div>
                  </div>
                </div>
                <div class="text-center mt-6">
                  <button
                      class="bg-yellow-400 text-white active:bg-yellow-300 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                  >
                    Log In
                  </button>
                </div>
                <div class="container mx-auto pt-2">
                  <div class="flex justify-center text-sm font-semibold">
                    {{ $t('forgot_password') }}
                    <router-link
                        to="/forgot"
                        v-slot="{ href, navigate }"
                    ><a :href="href" @click="navigate" class="text-yellow-500 hover:text-yellow-300 ml-1">{{ $t('click_here') }}</a>
                    </router-link>
                  </div>
                </div>
                <div class="container mx-auto pt-2">
                  <div class="flex justify-center text-sm font-semibold">
                    {{ $t('no_account') }}
                    <router-link
                        to="/signup"
                        v-slot="{ href, navigate }"
                    ><a :href="href" @click="navigate" class="text-yellow-500 hover:text-yellow-300 ml-1">{{ $t('signup') }}</a>
                    </router-link>
                  </div>
                </div>
                <div>
                  <img class="mx-auto pt-8" width="70%" alt="" :src="poweredBy" v-on:click="showUsers = !showUsers"/>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import User from '../models/user';
import logo from "@/assets/img/mylockersolution-logo.png";
import poweredBy from "@/assets/img/powered-by.png";
import AuthService from "../services/auth.service";

export default {
  name: 'Login',
  data() {
    return {
      logo,
      poweredBy,
      user: new User('', '', ''),
      showUsers: false,
      showPassword: false,
      picked: '',
      AuthService,
    };
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },

  async created() {
    const token = await AuthService.getAuthToken();
    if (this.loggedIn) {
      if (token.data.message === "super")
        await this.$router.push('/superuser');
      else if (token.data.message === "manager") {
        AuthService.getCurrentManager().then(
          response => async() => {
            if(response.data.message.is_new_user === 1)
              await this.$router.push('/manager/login');
            else
              await this.$router.push('/manager');
          });
      }
      else if (token.data.message === "standard")
        await this.$router.push('/user');
    }
  },

  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
    },

    handleLogin() {
      if (this.user.email && this.user.password) {
        if (this.picked === 'Superuser') {
          this.$store.dispatch('auth/superuserLogin', this.user).then(
              response => {
                if(response.status === "success")
                  this.$router.push('/superuser/');
                else {
                  this.$fire({
                    title: this.$t('error'),
                    text: this.$t('email_pass_wrong'),
                    type: "error",
                    confirmButtonColor: "#fbbf24",
                    timer: 3000
                  });
                }
              },
              error => {
                console.log(error);
              }
          );
        } else if (this.picked === 'Manager') {
          this.$store.dispatch('auth/managerLogin', this.user).then(
            response => {
              if(response.status === "success") {
                AuthService.getCurrentManager().then(
                    response => {
                      if (response.data.message.is_new_user === 1)
                        this.$router.push('/manager/login');
                      else
                        this.$router.push('/manager/');
                    });
              }
              else {
                this.$fire({
                  title: this.$t('error'),
                  text: this.$t('email_pass_wrong'),
                  type: "error",
                  confirmButtonColor: "#fbbf24",
                  timer: 3000
                });
              }
            },
                error => {
                  console.log(error);
                }
            );
        } else {
          this.$store.dispatch('auth/userLogin', this.user).then(
              response => {
                if(response.status === "success")
                  this.$router.push('/user/');
                else {
                  this.$fire({
                    title: this.$t('error'),
                    text: this.$t('email_pass_wrong'),
                    type: "error",
                    confirmButtonColor: "#fbbf24",
                    timer: 3000
                  });
                }
              },
              error => {
                console.log(error);
              }
          );
        }
      }
    }
  }
};
</script>
