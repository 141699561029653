<template>
  <section class="relative w-full h-full py-16 min-h-screen">
    <div class="absolute top-0 w-full h-full bg-yellow-300 bg-no-repeat bg-full"></div>
    <div class="container mx-auto px-4 h-full">
      <div class="flex content-center items-center justify-center h-full">
        <div class="w-full lg:w-4/12 px-4">
          <div
              class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
            <div class="rounded-t mb-0 px-6 py-6 text-center mx-auto font-bold text-3xl">
              <img class="mx-auto py-6" width="70%" alt="" :src="logo"/>
            </div>
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form @submit.prevent="managerLogin">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-gray-500 text-xs font-bold mb-2">
                    {{ $t('new_pass') }}
                  </label>
                  <input
                      v-model="user.password"
                      type="password"
                      class="border-0 px-3 py-3 placeholder-emerald-300 text-gray-500 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      name="password"
                      :placeholder="$t('new_pass')"
                  />
                </div>
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-gray-500 text-xs font-bold mb-2">
                    {{ $t('confirm_new_pass') }}
                  </label>
                  <input
                      v-model="confirmPassword"
                      type="password"
                      class="border-0 px-3 py-3 placeholder-emerald-300 text-gray-500 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      name="password"
                      :placeholder="$t('confirm_new_pass')"
                  />
                </div>
                <div class="text-center mt-6">
                  <button
                      class="bg-yellow-400 text-white active:bg-yellow-300 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                  >
                    {{ $t('change_pass') }}
                  </button>
                </div>
                <div>
                  <img class="mx-auto pt-8" width="70%" alt="" :src="poweredBy"/>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import logo from "@/assets/img/mylockersolution-logo.png";
import poweredBy from "@/assets/img/powered-by.png";
import AuthService from "../services/auth.service";
import User from "../models/user";

export default {
  name: 'Login',
  data() {
    return {
      logo,
      poweredBy,
      confirmPassword: '',
      user: new User('', '', ''),
      AuthService,
    };
  },

  methods: {
    validatePass(password) {
      return /[A-Z]/.test(password) &&
          /[a-z]/.test(password) &&
          /[0-9]/.test(password) &&
          /[^A-Za-z0-9]/.test(password) &&
          password.length >= 8;
    },

    managerLogin() {
      AuthService.getCurrentManager().then(
          response => {
            if (response.data.status === "success") {
              let manager = response.data.message;
              this.user.username = manager.username;
              this.user.email = manager.email;
              if (this.user.password === this.confirmPassword && this.user.password !== '' && this.confirmPassword !== '') {
                if (this.validatePass(this.user.password)) {
                  AuthService.editManager(manager.id, manager.username, manager.email, this.user.password, manager.name, manager.surname, manager.status, false).then(
                      response => {
                        if (response.data.status === "success") {
                          this.$router.push('/manager/');
                        } else {
                          this.$fire({
                            title: this.$t('error'),
                            html: this.$t('error_occurred') + ".<br>(" + response.data.message + ")",
                            type: "error",
                            confirmButtonColor: "#fbbf24",
                            timer: 3000
                          });
                        }
                      });
                } else {
                  this.$fire({
                    title: this.$t('error'),
                    text: this.$t('insert_valid_pass'),
                    type: "error",
                    confirmButtonColor: "#fbbf24",
                    timer: 4000
                  });
                }
              } else {
                this.$fire({
                  title: this.$t('error'),
                  text: this.$t('equal_pass'),
                  type: "error",
                  confirmButtonColor: "#fbbf24",
                  timer: 3000
                });
              }
            }
          });
    }
  }
};
</script>
