<template>
  <div>
    <a
        class="text-blueGray-500 py-1 px-3"
        href="#pablo"
        ref="btnDropdownRef"
        v-on:click="toggleDropdown($event)"
    >
      <i class="fas fa-ellipsis-v"></i>
    </a>
    <div
        ref="popoverDropdownRef"
        class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
        v-click-outside="hideDropdown"
    >
      <a
          href="javascript:void(0);"
          class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          v-on:click="editDrawer"
      >
        {{ $t('edit') }}
      </a>
      <a
          href="javascript:void(0);"
          class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          v-on:click="deleteDrawer"
      >
        {{ $t('delete') }}
      </a>
      <a
          href="javascript:void(0);"
          class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          v-on:click="openDrawer"
      >
        {{ $t('force_open') }}
      </a>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2';
import { createPopper } from "@popperjs/core";
import AuthService from "../../../services/auth.service";

export default {
  data() {
    return {
      dropdownPopoverShow: false,
      dropdownClicksCounter: 0,
      AuthService,
    };
  },

  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },

    hideDropdown: function () {
      this.dropdownClicksCounter++;
      if(this.dropdownClicksCounter === 2) {
        this.dropdownClicksCounter = 0;
        if(this.dropdownPopoverShow)
          this.dropdownPopoverShow = !this.dropdownPopoverShow;
      }
    },

    openDrawer: function () {
      // chiede conferma apertura cassetto
      this.$fire({
        title: this.$t('confirm_open'),
        text: this.$t('sure_open'),
        type: "question",
        confirmButtonColor: "#fbbf24",
        showCancelButton: true,
      }).then(response => {
        if (response.value === true) {
          let drawer = this.$parent.drawers[this.$props.index];
          // nel caso di risposta positiva verifica che il cassetto sia aperto
          if(drawer.door_state === 1) {
            // se il cassetto è aperto chiede se lo si vuole aprire comunque
            this.$fire({
              title: this.$t('confirm_open'),
              text: this.$t('drawer_already_opened'),
              type: "question",
              confirmButtonColor: "#fbbf24",
              showCancelButton: true,
            }).then(response => {
              if (response.value === true) {
                // apre il cassetto
                AuthService.openDrawer(drawer.id, drawer.locker_id, true).then(
                    response => {
                      if (response.data.status === "success") {
                        // si apre una finestra di caricamento che dura 5 secondi
                        Swal.fire({
                          title: this.$t('opening_drawer'),
                          html: this.$t('loading'),
                          allowOutsideClick: false,
                          onBeforeOpen: () => {
                            Swal.showLoading()
                          },
                        });
                        const delay = t => new Promise(resolve => setTimeout(resolve, t));
                        delay(5000).then(() => AuthService.getDrawersByLockerId(drawer.locker_id).then(
                            response => {
                              // lo stato dei cassetti viene aggioranto
                              this.$parent.drawers = response.data.message;
                              let drawer = this.$parent.drawers[this.$props.index];
                              if(drawer.door_state === 1) {
                                this.$fire({
                                  title: this.$t('success'),
                                  text: this.$t('drawer_opened_succ'),
                                  type: "success",
                                  confirmButtonColor: "#fbbf24",
                                  timer: 3000
                                });
                              } else {
                                this.$fire({
                                  title: this.$t('error'),
                                  text: this.$t('impossible_to_open'),
                                  type: "error",
                                  confirmButtonColor: "#fbbf24",
                                  timer: 3000
                                });
                              }
                            }
                        ));
                      } else {
                        this.$fire({
                          title: this.$t('error'),
                          html: this.$t('error_occurred') + ".<br>(" + response.data.message + ")",
                          type: "error",
                          confirmButtonColor: "#fbbf24",
                          timer: 3000
                        });
                      }
                    });
              }
            });
          } else {
            // uguale a sopra, ma nel caso semplice in cui un cassetto è chiuso
            AuthService.openDrawer(drawer.id, drawer.locker_id, true).then(
                response => {
                  if (response.data.status === "success") {
                    Swal.fire({
                      title: this.$t('opening_drawer'),
                      html: this.$t('loading'),
                      allowOutsideClick: false,
                      onBeforeOpen: () => {
                        Swal.showLoading()
                      },
                    });
                    const delay = t => new Promise(resolve => setTimeout(resolve, t));
                    delay(5000).then(() => AuthService.getDrawersByLockerId(drawer.locker_id).then(
                          response => {
                            this.$parent.drawers = response.data.message;
                            let drawer = this.$parent.drawers[this.$props.index];
                            if(drawer.door_state === 1) {
                              this.$fire({
                                title: this.$t('success'),
                                text: this.$t('drawer_opened_succ'),
                                type: "success",
                                confirmButtonColor: "#fbbf24",
                                timer: 3000
                              });
                            } else {
                              this.$fire({
                                title: this.$t('error'),
                                text: this.$t('impossible_to_open'),
                                type: "error",
                                confirmButtonColor: "#fbbf24",
                                timer: 3000
                              });
                            }
                          }
                      ));
                  } else {
                    this.$fire({
                      title: this.$t('error'),
                      html: this.$t('error_occurred') + ".<br>(" + response.data.message + ")",
                      type: "error",
                      confirmButtonColor: "#fbbf24",
                      timer: 3000
                    });
                  }
                });
          }
        }
      });
    },

    editDrawer: function () {
      this.$parent.setDrawerIndex(this.$props.index);
      this.$parent.toggleEditDrawer();
    },

    deleteDrawer: function() {
      this.$fire({
        title: this.$t('confirm_delete'),
        text: this.$t('confirm_delete_drawer'),
        type: "question",
        confirmButtonColor: "#fbbf24",
        showCancelButton: true,
      }).then(response => {
        if (response.value === true) {
          // cancella il cassetto
          let drawer = this.$parent.drawers[this.$props.index];
          AuthService.deleteDrawer(drawer.locker_id, drawer.id, true).then(
              response => {
                if (response.data.status === "success") {
                  // aggiorna i cassetti in caso di successo
                  AuthService.getDrawersByLockerId(drawer.locker_id).then(
                      response => {
                        this.$parent.drawers = response.data.message;
                      }
                  );
                  this.$fire({
                    title: this.$t('success'),
                    text: this.$t('drawer_deleted_succ'),
                    type: "success",
                    confirmButtonColor: "#fbbf24",
                    timer: 3000
                  });
                } else {
                  this.$fire({
                    title: this.$t('error'),
                    html: this.$t('error_occurred') + ".<br>(" + response.data.message + ")",
                    type: "error",
                    confirmButtonColor: "#fbbf24",
                    timer: 3000
                  });
                }
              });
        }
      });
    }
  },

  props: {
    index: Number,
  }
};
</script>
