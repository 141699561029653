<template>
  <div
      class="relative flex flex-col min-w-0 break-words w-full rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">{{ $t('edit_your_profile') }}</h6>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ $t('superuser_info') }}
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                {{ $t('name') }}
              </label>
              <input
                  type="text"
                  :placeholder="$t('name')"
                  v-model="name"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                {{ $t('surname') }}
              </label>
              <input
                  type="text"
                  :placeholder="$t('surname')"
                  v-model="surname"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Username
              </label>
              <input
                  type="text"
                  placeholder="Surname"
                  v-model="username"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Email
              </label>
              <input
                  type="email"
                  placeholder="Email"
                  v-model="email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>
          <div class="w-full w-full px-4">
            <div class="relative w-full mb-3">
              <label class="inline-flex items-center cursor-pointer">
                <input id="checkPublic" type="checkbox"
                       class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                       @change="showChangePassword">
                <span class="ml-2 text-sm font-semibold text-blueGray-600">{{ $t('change_pass') }}</span>
              </label>
            </div>
          </div>
          <template v-if="changePassword">
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                  {{ $t('new_pass') }}
                </label>
                <input
                    type="password"
                    :placeholder="$t('new_pass')"
                    v-model="newPassword"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                />
              </div>
            </div>
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                  {{ $t('confirm_new_pass') }}
                </label>
                <input
                    type="password"
                    :placeholder="$t('confirm_new_pass')"
                    v-model="confirmNewPassword"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                />
              </div>
            </div>
          </template>
          <div
              class="w-full flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b py-3">
            <button
                class="bg-yellow-400 text-white ative:bg-yellow-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                v-on:click="formSubmit">
              {{ $t('save_changes') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import AuthService from "../../../services/auth.service";

export default {
  data() {
    return {
      email: '',
      name: '',
      surname: '',
      username: '',
      changePassword: false,
      newPassword: '',
      confirmNewPassword: '',
      user: '',
      AuthService
    };
  },

  mounted() {
    AuthService.getCurrentSuperuser().then(
        response => {
          this.user = response.data.message;
          this.email = this.user.email;
          this.username = this.user.username;
          this.name = this.user.name;
          this.surname = this.user.surname;
        }
    );
  },

  methods: {
    showChangePassword: function () {
      this.changePassword = !this.changePassword;
    },

    validateEmail(email) {
      let re = /\S+@\S+\.\S+/;
      return re.test(email);
    },

    formSubmit: function () {
      if (this.user === '' || this.email === '' || this.username === '' || this.name === '' || this.surname === '') {
        this.$fire({
          title: this.$t('error'),
          text: this.$t('all_fields_full'),
          type: "error",
          confirmButtonColor: "#fbbf24",
          timer: 3000
        });
      } else {
        if(this.validateEmail(this.email)) {
          // aggiorna il profilo se tutti i campi sono pieni, verifica che la password sia stata modificata
          if (this.changePassword) {
            if (this.newPassword === this.confirmNewPassword && this.newPassword !== '' && this.confirmNewPassword !== '') {
              AuthService.editSuperuser(this.user.id, this.username, this.email, this.newPassword, this.name, this.surname, this.user.status).then(
                  response => {
                    if (response.data.status === "success") {
                      this.$fire({
                        title: this.$t('success'),
                        text: this.$t('profile_edited_succ'),
                        type: "success",
                        confirmButtonColor: "#fbbf24",
                        timer: 3000
                      });
                    } else {
                      this.$fire({
                        title: this.$t('error'),
                        html: this.$t('error_occurred') + ".<br>(" + response.data.message + ")",
                        type: "error",
                        confirmButtonColor: "#fbbf24",
                        timer: 3000
                      });
                    }
                  });
            } else {
              this.$fire({
                title: this.$t('error'),
                text: this.$t('insert_valid_pass'),
                type: "error",
                confirmButtonColor: "#fbbf24",
                timer: 3000
              });
            }
          } else {
            AuthService.editSuperuser(this.user.id, this.username, this.email, false, this.name, this.surname, this.user.status).then(
                response => {
                  if (response.data.status === "success") {
                    this.$fire({
                      title: this.$t('success'),
                      text: this.$t('profile_edited_succ'),
                      type: "success",
                      confirmButtonColor: "#fbbf24",
                      timer: 3000
                    });
                  } else {
                    this.$fire({
                      title: this.$t('error'),
                      html: this.$t('error_occurred') + ".<br>(" + response.data.message + ")",
                      type: "error",
                      confirmButtonColor: "#fbbf24",
                      timer: 3000
                    });
                  }
                });
          }
        } else {
          this.$fire({
            title: this.$t('error'),
            text: this.$t('insert_valid_email'),
            type: "error",
            confirmButtonColor: "#fbbf24",
            timer: 3000
          });
        }
      }
    }
  }
}
</script>
