<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div
            class="relative flex flex-col min-w-0 break-words w-full rounded-lg bg-blueGray-100 border-0"
        >
          <div class="rounded-t bg-white mb-0 px-6 py-6">
            <div class="text-center flex justify-between">
              <h6 class="text-blueGray-700 text-xl font-bold">{{ $t('edit_manager_roles') }}</h6>
            </div>
          </div>
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form>
              <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                {{ $t('roles_info') }}
              </h6>
              <div class="flex flex-wrap">
                <div class="w-full lg:w-6/12 px-4">
                  <div class="relative w-full mb-3">
                    <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                      {{ $t('modify_locker') }}
                    </label>
                    <select v-model="modifyLocker"
                            type="text"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                      <option value="0">✗</option>
                      <option value="1">✓</option>
                    </select>
                  </div>
                </div>
                <div class="w-full lg:w-6/12 px-4">
                  <div class="relative w-full mb-3">
                    <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                      {{ $t('add_users') }}
                    </label>
                    <select v-model="addUsers"
                            type="text"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                      <option value="0">✗</option>
                      <option value="1">✓</option>
                    </select>
                  </div>
                </div>
                <div
                    class="w-full flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b py-3">
                  <button
                      class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      v-on:click="toggleModal">
                    {{ $t('close') }}
                  </button>
                  <button
                      class="bg-yellow-400 text-white ative:bg-yellow-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      v-on:click="formSubmit">
                    {{ $t('save_changes') }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AuthService from "../../../services/auth.service";

export default {
  data() {
    return {
      lockerId: '',
      modifyLocker: '',
      addUsers: '',
      AuthService
    };
  },

  mounted() {
    let role = this.$parent.roles[this.$props.index];
    this.lockerId = role.locker_id;
    this.modifyLocker = role.modify_locker;
    this.addUsers = role.add_users;
  },

  methods: {
    toggleModal: function () {
      this.$parent.toggleEditRoles();
    },

    formSubmit: function () {
      let role = this.$parent.roles[this.$props.index];
      let userId = role.user_id;
      if (this.modifyLocker !== '' || this.addUsers !== '') {
        // Modifica i permessi del manager sul locker
        AuthService.setRolesOfManager(userId, this.lockerId, this.modifyLocker, this.addUsers).then(
            response => {
              if (response.data.status === "success") {
                // aggiorna i permessi del manager
                AuthService.getRolesOfManager(userId).then(
                    response => {
                      this.$parent.roles = response.data.message;
                    }
                );
                this.$fire({
                  title: this.$t('success'),
                  text: this.$t('manager_roles_edited_succ'),
                  type: "success",
                  confirmButtonColor: "#fbbf24",
                  timer: 3000
                });
              } else {
                this.$fire({
                  title: this.$t('error'),
                  html: this.$t('error_occurred') + ".<br>(" + response.data.message + ")",
                  type: "error",
                  confirmButtonColor: "#fbbf24",
                  timer: 3000
                });
              }
            }
        );
      } else {
        this.$fire({
          title: this.$t('error'),
          text: this.$t('all_fields_full'),
          type: "error",
          confirmButtonColor: "#fbbf24",
          timer: 3000
        });
      }
      this.toggleModal();
    }
  },

  props: {
    index: Number,
  }
};
</script>
