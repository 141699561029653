<template>
  <section class="relative w-full h-full py-16 min-h-screen">
    <div class="absolute top-0 w-full h-full bg-yellow-300 bg-no-repeat bg-full"></div>
    <div class="container mx-auto px-4 h-full">
      <div class="flex content-center items-center justify-center h-full">
        <div class="w-full lg:w-4/12 px-4">
          <div
              class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
            <div class="rounded-t mb-0 px-6 py-6 text-center mx-auto font-bold text-3xl">
              <img class="mx-auto py-6" width="70%" alt="" :src="logo"/>
            </div>
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form @submit.prevent="signUp">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-gray-500 text-xs font-bold mb-2">
                    {{ $t('name') }}
                  </label>
                  <input
                      v-model="name"
                      type="text"
                      class="border-0 px-3 py-3 placeholder-emerald-300 text-gray-500 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      name="name"
                      :placeholder="$t('name')"
                  />
                </div>
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-gray-500 text-xs font-bold mb-2">
                    {{ $t('surname') }}
                  </label>
                  <input
                      v-model="surname"
                      type="text"
                      class="border-0 px-3 py-3 placeholder-emerald-300 text-gray-500 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      name="Surname"
                      :placeholder="$t('surname')"
                  />
                </div>
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-gray-500 text-xs font-bold mb-2">
                    Username
                  </label>
                  <input
                      v-model="username"
                      type="text"
                      class="border-0 px-3 py-3 placeholder-emerald-300 text-gray-500 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      name="username"
                      placeholder="Username"
                  />
                </div>
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-gray-500 text-xs font-bold mb-2">
                    Email
                  </label>
                  <input
                      v-model="email"
                      type="email"
                      class="border-0 px-3 py-3 placeholder-emerald-300 text-gray-500 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      name="email"
                      placeholder="Email"
                  />
                </div>
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-gray-500 text-xs font-bold mb-2">
                    Password
                  </label>
                  <div class="flex justify-between">
                    <input
                        v-if="!showPassword"
                        v-model="password"
                        type="password"
                        class="border-0 px-3 py-3 placeholder-emerald-300 text-gray-500 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        name="password"
                        placeholder="Password"
                    />
                    <input
                        v-else
                        v-model="password"
                        type="text"
                        class="border-0 px-3 py-3 placeholder-emerald-300 text-gray-500 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        name="password"
                        placeholder="Password"
                    />
                    <button
                        class="bg-yellow-400 text-white active:bg-yellow-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear ml-1 transition-all duration-150"
                        type="button"
                        v-on:click="togglePassword()"
                    >
                      <i v-if="!showPassword" class="fas fa-eye-slash text-sm"></i>
                      <i v-else class="fas fa-eye text-sm"></i>
                    </button>
                  </div>
                </div>
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-gray-500 text-xs font-bold mb-2">
                    {{ $t('confirm_pass') }}
                  </label>
                  <div class="flex justify-between">
                    <input
                        v-if="!showConfirmPassword"
                        v-model="confirmPassword"
                        type="password"
                        class="border-0 px-3 py-3 placeholder-emerald-300 text-gray-500 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        name="password"
                        placeholder="Password"
                    />
                    <input
                        v-else
                        v-model="confirmPassword"
                        type="text"
                        class="border-0 px-3 py-3 placeholder-emerald-300 text-gray-500 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        name="password"
                        placeholder="Password"
                    />
                    <button
                        class="bg-yellow-400 text-white active:bg-yellow-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear ml-1 transition-all duration-150"
                        type="button"
                        v-on:click="toggleConfirmPassword()"
                    >
                      <i v-if="!showConfirmPassword" class="fas fa-eye-slash text-sm"></i>
                      <i v-else class="fas fa-eye text-sm"></i>
                    </button>
                  </div>
                </div>
                <div class="text-center mt-6">
                  <button
                      class="bg-yellow-400 text-white active:bg-yellow-300 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                  >
                    {{ $t('signup') }}
                  </button>
                </div>
              </form>
              <div class="container mx-auto pt-2">
                <div class="flex justify-center text-sm font-semibold">
                  {{ $t('yes_account') }}
                  <router-link
                      to="/login"
                      v-slot="{ href, navigate }"
                  ><a :href="href" @click="navigate" class="text-yellow-500 hover:text-yellow-300 ml-1">Login</a>
                  </router-link>
                </div>
              </div>
              <div>
                <img class="mx-auto pt-8" width="70%" alt="" :src="poweredBy"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import logo from "@/assets/img/mylockersolution-logo.png";
import poweredBy from "@/assets/img/powered-by.png";
import AuthService from "../services/auth.service";

export default {
  name: 'Login',
  data() {
    return {
      logo,
      poweredBy,
      name: '',
      surname: '',
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
      showPassword: false,
      showConfirmPassword: false,
      AuthService,
    };
  },

  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
    },

    toggleConfirmPassword() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },

    validateEmail(email) {
      let re = /\S+@\S+\.\S+/;
      return re.test(email);
    },

    validatePass(password) {
      return /[A-Z]/.test(password) &&
          /[a-z]/.test(password) &&
          /[0-9]/.test(password) &&
          /[^A-Za-z0-9]/.test(password) &&
          password.length >= 8;
    },

    signUp() {
      if (this.email === '' || this.username === '' || this.name === '' || this.surname === '') {
        this.$fire({
          title: this.$t('error'),
          text: this.$t('all_fields_full'),
          type: "error",
          confirmButtonColor: "#fbbf24",
          timer: 3000
        });
      } else {
        if (this.validateEmail(this.email)) {
          if (this.password === this.confirmPassword && this.password !== '' && this.confirmPassword !== '') {
            if (this.validatePass(this.password)) {
              AuthService.createUser(this.username, this.email, this.password, this.name, this.surname).then(
                  response => {
                    if (response.data.status === "success") {
                      this.$fire({
                        title: this.$t('success'),
                        text: this.$t('confirm_email'),
                        type: "success",
                        confirmButtonColor: "#fbbf24",
                        timer: 6000
                      });
                      this.$router.push('/login');
                    } else {
                      this.$fire({
                        title: this.$t('error'),
                        html: this.$t('error_occurred') + ".<br>(" + response.data.message + ")",
                        type: "error",
                        confirmButtonColor: "#fbbf24",
                        timer: 3000
                      });
                    }
                  }
              );
            } else {
              this.$fire({
                title: this.$t('error'),
                text: this.$t('insert_valid_pass'),
                type: "error",
                confirmButtonColor: "#fbbf24",
                timer: 4000
              });
            }
          } else {
            this.$fire({
              title: this.$t('error'),
              text: this.$t('equal_pass'),
              type: "error",
              confirmButtonColor: "#fbbf24",
              timer: 4000
            });
          }
        } else {
          this.$fire({
            title: this.$t('error'),
            text: this.$t('insert_valid_email'),
            type: "error",
            confirmButtonColor: "#fbbf24",
            timer: 3000
          });
        }
      }
    }
  }
};
</script>
