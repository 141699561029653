<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div
            class="relative flex flex-col min-w-0 break-words w-full rounded-lg bg-blueGray-100 border-0"
        >
          <div class="rounded-t bg-white mb-0 px-6 py-6">
            <div class="text-center flex justify-between">
              <h6 class="text-blueGray-700 text-xl font-bold">{{ $t('create') }} {{ $t('new') }} {{ $t('drawer') }}</h6>
            </div>
          </div>
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form>
              <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                {{ $t('drawer_info') }}
              </h6>
              <div class="flex flex-wrap">
                <div class="w-full lg:w-6/12 px-4">
                  <div class="relative w-full mb-3">
                    <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                      {{ $t('slave_id') }}
                    </label>
                    <input
                        v-model="slaveId"
                        type="text"
                        :placeholder="$t('slave_id')"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
                <div class="w-full lg:w-6/12 px-4">
                  <div class="relative w-full mb-3">
                    <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                      {{ $t('solenoid_id') }}
                    </label>
                    <input
                        v-model="solenoidId"
                        type="text"
                        :placeholder="$t('solenoid_id')"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
                <div class="w-full lg:w-6/12 px-4">
                  <div class="relative w-full mb-3">
                    <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                      {{ $t('size') }}
                    </label>
                    <select v-model="sizeCode"
                            type="text"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                      <option value="s" selected>S</option>
                      <option value="m">M</option>
                      <option value="l">L</option>
                      <option value="xl">XL</option>
                    </select>
                  </div>
                </div>
                <div
                    class="w-full flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b py-3">
                  <button
                      class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button" v-on:click="toggleModal">
                    {{ $t('close') }}
                  </button>
                  <button
                      class="bg-yellow-400 text-white ative:bg-yellow-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button" v-on:click="formSubmit">
                    {{ $t('create') }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AuthService from "../../../services/auth.service";

export default {
  data() {
    return {
      lockerId: '',
      slaveId: '',
      solenoidId: '',
      sizeCode: '',
      AuthService
    };
  },

  mounted() {
    this.lockerId = this.$parent.locker.id;
  },

  methods: {
    toggleModal: function () {
      this.$parent.toggleNewDrawer();
    },

    formSubmit(e) {
      e.preventDefault();
      if (this.slaveId !== '' || this.solenoidId !== '' || this.sizeCode !== '' || this.awaitingOperation !== '') {
        // Se i cassetti sono 16 e se ne vuole creare un altro si apre un warning
        if(this.$parent.drawers.length >= 16) {
          this.$fire({
            title: this.$t('confirm_open'),
            text: this.$t('drawer_already_opened'),
            type: "question",
            confirmButtonColor: "#fbbf24",
            showCancelButton: true,
          }).then(response => {
            if (response.value === true) {
              // crea nuovo cassetto
              AuthService.createDrawer(this.lockerId, this.slaveId, this.solenoidId, this.sizeCode).then(
                  response => {
                    if (response.data.status === "success") {
                      // aggiorna i cassetti dell'armadio
                      AuthService.getDrawersByLockerId(this.lockerId).then(
                          response => {
                            this.$parent.drawers = response.data.message;
                          }
                      );
                      this.$fire({
                        title: this.$t('success'),
                        text: this.$t('drawer_created_succ'),
                        type: "success",
                        confirmButtonColor: "#fbbf24",
                        timer: 3000
                      });
                    } else {
                      this.$fire({
                        title: this.$t('error'),
                        html: this.$t('error_occurred') + ".<br>(" + response.data.message + ")",
                        type: "error",
                        confirmButtonColor: "#fbbf24",
                        timer: 3000
                      });
                    }
                  });
            }
          });
          // crea nuovo cassetto (se voglio crearlo comunque anche dopo il warning)
          AuthService.createDrawer(this.lockerId, this.slaveId, this.solenoidId, this.sizeCode).then(
              response => {
                if (response.data.status === "success") {
                  // aggiorna i cassetti
                  AuthService.getDrawersByLockerId(this.lockerId).then(
                      response => {
                        this.$parent.drawers = response.data.message;
                      }
                  );
                  this.$fire({
                    title: this.$t('success'),
                    text: this.$t('drawer_created_succ'),
                    type: "success",
                    confirmButtonColor: "#fbbf24",
                    timer: 3000
                  });
                } else {
                  this.$fire({
                    title: this.$t('error'),
                    html: this.$t('error_occurred') + ".<br>(" + response.data.message + ")",
                    type: "error",
                    confirmButtonColor: "#fbbf24",
                    timer: 3000
                  });
                }
              });
        } else {
          AuthService.createDrawer(this.lockerId, this.slaveId, this.solenoidId, this.sizeCode).then(
              response => {
                if (response.data.status === "success") {
                  // aggiorna i cassetti dell'armadio
                  AuthService.getDrawersByLockerId(this.lockerId).then(
                      response => {
                        this.$parent.drawers = response.data.message;
                      }
                  );
                  this.$fire({
                    title: this.$t('success'),
                    text: this.$t('drawer_created_succ'),
                    type: "success",
                    confirmButtonColor: "#fbbf24",
                    timer: 3000
                  });
                } else {
                  this.$fire({
                    title: this.$t('error'),
                    html: this.$t('error_occurred') + ".<br>(" + response.data.message + ")",
                    type: "error",
                    confirmButtonColor: "#fbbf24",
                    timer: 3000
                  });
                }
              });
        }
      } else {
        this.$fire({
          title: this.$t('error'),
          text: this.$t('all_fields_full'),
          type: "error",
          confirmButtonColor: "#fbbf24",
          timer: 3000
        });
      }
      this.toggleModal();
    }
  }
}
</script>
