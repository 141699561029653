<template>
  <div class="flex flex-wrap mt-4 min-height-view">
    <div class="w-full mb-12 px-4">
      <card-codes/>
    </div>
  </div>
</template>
<script>
import CardCodes from "@/components/Cards/User/CardCodes.vue";

export default {
  components: {
    CardCodes,
  },
};
</script>
