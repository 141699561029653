<template>
  <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
      :class="[color === 'light' ? 'bg-white' : 'bg-yellow-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="px-3 text-center flex justify-between">
        <h3
            class="font-semibold text-lg"
            :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
        >
          {{ $tc('locker', 2) }}
        </h3>
        <div>
          <span
              class="z-10 leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3"
          >
            <i class="fas fa-search"></i>
          </span>
          <input
              type="text"
              :placeholder="$t('search')"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow mr-3 outline-none focus:outline-none focus:ring pl-10"
              v-model="filter"
          />
          <button
              class="bg-yellow-400 text-white ative:bg-yellow-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
              v-on:click="toggleNewLocker"
          >
            <i class="fas fa-plus text-sm mx-auto"></i>

          </button>
        </div>
      </div>
    </div>
    <card-new-locker v-if="showNewLocker" />
    <card-edit-locker v-if="showEditLocker" v-bind:index="lockerIndex"/>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
        <tr>
          <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-yellow-800 text-yellow-300 border-emerald-700',
              ]"
          >
            {{ $t('name') }}
          </th>
          <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-yellow-800 text-yellow-300 border-emerald-700',
              ]"
          >
            {{ $t('address') }}
          </th>
          <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-yellow-800 text-yellow-300 border-emerald-700',
              ]"
          ></th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(locker, index) in filteredLockers">
          <tr v-if="locker.id > 0" :key="locker.id">
            <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <div v-if="locker.name === ''">-</div>
              <div v-else>{{ locker.name }}</div>
            </td>
            <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <div v-if="locker.address === ''">-</div>
              <div v-else>{{ locker.address }}</div>
            </td>
            <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
            >
              <lockers-dropdown v-bind:index="index"/>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import LockersDropdown from "@/components/Dropdowns/Superuser/LockersDropdown.vue";
import CardNewLocker from "@/components/Cards/Superuser/CardNewLocker.vue";
import CardEditLocker from "@/components/Cards/Superuser/CardEditLocker.vue";
import AuthService from "../../../services/auth.service";

export default {
  name: 'Lockers',
  data() {
    return {
      lockers: [],
      showNewLocker: false,
      showEditLocker: false,
      lockerIndex: '',
      lockerId: '',
      filter: '',
      AuthService
    };
  },

  computed: {
    filteredLockers() {
      return this.lockers.filter(locker => {
        const name = locker.name.toString().toLowerCase();
        const address = locker.address.toLowerCase();
        const searchTerm = this.filter.toLowerCase();

        return name.includes(searchTerm) || address.includes(searchTerm);
      });
    }
  },

  mounted: function () {
    AuthService.getLockers().then(
        response => {
          this.lockers = response.data.message;
        }
    );
    window.setInterval(() => {
      AuthService.getLockers().then(
          response => {
            this.lockers = response.data.message;
          }
      );
    }, 20000)
  },

  methods: {
    showLockerData: function (locker) {
      this.$parent.$props.locker = locker;
      this.$parent.showLockerData = true;
    },

    toggleNewLocker: function () {
      this.showNewLocker = !this.showNewLocker;
      if (this.showEditLocker) this.showEditLocker = !this.showEditLocker;
    },

    toggleEditLocker: function() {
      this.showEditLocker = !this.showEditLocker;
      if (this.showNewLocker) this.showNewLocker = !this.showNewLocker;
    },

    setLockerIndex: function(index) {
      this.lockerIndex = index;
    },
  },
  components: {
    LockersDropdown,
    CardNewLocker,
    CardEditLocker,
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
