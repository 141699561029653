<template>
  <div>
    <a
      class="text-blueGray-500 block"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <div class="items-center flex">
        <span class="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full">
          <i class="fas fa-user text-2xl"></i>
        </span>
      </div>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
      v-click-outside="hideDropdown"
    >
      <a
          class="text-xs py-2 px-4 font-bold items-center text-gray-500 font-normal block w-full whitespace-nowrap bg-transparent"
      >
        {{ $tc('superuser', 1) }}
      </a>
      <a
          @click="editProfile"
          href="javascript:void(0);"
          class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        {{ $t('edit_profile') }}
      </a>
      <a
        @click="logout"
        href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        Logout
      </a>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
export default {
  data() {
    return {
      dropdownPopoverShow: false,
      dropdownClicksCounter: 0,
    };
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },

    hideDropdown: function () {
      this.dropdownClicksCounter++;
      if(this.dropdownClicksCounter === 2) {
        this.dropdownClicksCounter = 0;
        if(this.dropdownPopoverShow)
          this.dropdownPopoverShow = !this.dropdownPopoverShow;
      }
    },

    editProfile: function() {
      this.$router.push("/superuser/edit");
    },

    async logout() {
      await this.$store.dispatch("auth/logout");
      await this.$router.push("/login");
    },
  },
};
</script>
