<template>
  <div class="flex flex-wrap mt-4 min-height-view">
    <div v-if="!showManagerDetails" class="w-full mb-12 px-4">
      <card-locker-managers />
    </div>
    <div v-if="showManagerDetails" class="w-full mb-12 px-4">
      <card-manager-lockers />
    </div>
    <div v-if="showManagerDetails" class="w-full mb-12 px-4">
      <card-manager-roles />
    </div>
  </div>
</template>
<script>
import CardLockerManagers from "@/components/Cards/Superuser/CardLockerManagers.vue";
import CardManagerLockers from "@/components/Cards/Superuser/CardManagerLockers.vue";
import CardManagerRoles from "@/components/Cards/Superuser/CardManagerRoles.vue";

export default {
  data() {
    return {
      showManagerDetails: false,
    };
  },

  components: {
    CardLockerManagers,
    CardManagerLockers,
    CardManagerRoles,
  },

  props: {
    manager: Object,
  }
};
</script>
