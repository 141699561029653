import Vue from "vue";
import VueRouter from "vue-router";

// layouts
import Superuser from "./layouts/Superuser.vue";
import LockerManager from "./layouts/LockerManager.vue";
import StandardUser from "./layouts/StandardUser.vue";

// views for Superuser layout
import SuperuserDashboard from "./views/superuser/Dashboard.vue";
import SuperuserProfile from "./views/superuser/EditProfile.vue";
import SuperuserSuperusers from "./views/superuser/Superusers.vue";
import SuperuserManagers from "./views/superuser/Managers.vue";
import SuperuserUsers from "./views/superuser/Users.vue";
import SuperuserLockers from "./views/superuser/Lockers.vue";

// views for Locker Manager layout
import LockerManagerProfile from "./views/manager/EditProfile.vue";
import LockerManagerUsers from "./views/manager/Users.vue";
import LockerManagerLockers from "./views/manager/Lockers.vue";

// views for Standard User layout
import StandardUserProfile from "./views/user/EditProfile.vue";
import StandardUserCodes from "./views/user/Codes.vue";
import StandardUserLockers from "./views/user/Lockers.vue";
import StandardUserPackages from "./views/user/Packages.vue";
import StandardUserHistory from "./views/user/History.vue";

// views for Auth layout
import Login from "./views/Login.vue";
import Signup from "./views/Signup.vue";
import Activate from "./views/Activate.vue";
import ManagerFirstLogin from "./views/ManagerFirstLogin.vue";
import Forgot from "./views/Forgot.vue";
import Recover from "./views/Recover.vue";
import UserChangePassword from "./views/UserChangePassword";

Vue.use(VueRouter);

const routes = [
    {
        path: "/superuser",
        redirect: "/superuser/lockers",
        component: Superuser,
        children: [
            {
                path: "/superuser/dashboard",
                component: SuperuserDashboard,
            },
            {
                path: "/superuser/superusers",
                component: SuperuserSuperusers,
            },
            {
                path: "/superuser/managers",
                component: SuperuserManagers,
            },
            {
                path: "/superuser/users",
                component: SuperuserUsers,
            },
            {
                path: "/superuser/lockers",
                component: SuperuserLockers,
            },
            {
                path: "/superuser/edit",
                component: SuperuserProfile,
            },

        ],
    },
    {
        path: "/manager",
        redirect: "/manager/lockers",
        component: LockerManager,
        children: [
            {
                path: "/manager/users",
                component: LockerManagerUsers,
            },
            {
                path: "/manager/lockers",
                component: LockerManagerLockers,
            },
            {
                path: "/manager/edit",
                component: LockerManagerProfile,
            },

        ],
    },
    {
        path: "/user",
        redirect: "/user/lockers",
        component: StandardUser,
        children: [
            {
                path: "/user/codes",
                component: StandardUserCodes,
            },
            {
                path: "/user/lockers",
                component: StandardUserLockers,
            },
            {
                path: "/user/packages",
                component: StandardUserPackages,
            },
            {
                path: "/user/history",
                component: StandardUserHistory,
            },
            {
                path: "/user/edit",
                component: StandardUserProfile,
            },

        ],
    },
    {
        path: "/login",
        component: Login,
    },
    {
        path: "/signup",
        component: Signup,
    },
    {
        path: "/activate/:userId/:token",
        component: Activate,
    },
    {
        path: "/forgot",
        component: Forgot,
    },
    {
        path: "/recover/:token",
        component: Recover,
    },
    {
        path: "/change_password",
        component: UserChangePassword,
    },
    {
        path: "/manager/login",
        component: ManagerFirstLogin,
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: "/login"
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
});

export default router;
