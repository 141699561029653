<template>
  <div>
    <a
        class="text-blueGray-500 py-1 px-3"
        href="#pablo"
        ref="btnDropdownRef"
        v-on:click="toggleDropdown($event)"
    >
      <i class="fas fa-ellipsis-v"></i>
    </a>
    <div
        ref="popoverDropdownRef"
        class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
        v-click-outside="hideDropdown"
    >
      <a
          @click="approveUserToLocker"
          href="javascript:void(0);"
          class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        {{ $t('approve_user') }}
      </a>
    </div>
  </div>
</template>
<script>
import { createPopper } from "@popperjs/core";
import AuthService from "../../services/auth.service";

export default {
  data() {
    return {
      dropdownPopoverShow: false,
      dropdownClicksCounter: 0,
      AuthService
    };
  },

  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },

    hideDropdown: function () {
      this.dropdownClicksCounter++;
      if(this.dropdownClicksCounter === 2) {
        this.dropdownClicksCounter = 0;
        if(this.dropdownPopoverShow)
          this.dropdownPopoverShow = !this.dropdownPopoverShow;
      }
    },

    approveUserToLocker: async function () {
      let locker = this.$parent.locker;
      let user = this.$parent.awaitingUsers[this.$props.index];
      AuthService.approveUserToLocker(locker.id, user.id).then(
          response => {
            if (response.data.status === "success") {
              // approva l'utente all'armadio
              AuthService.getAwaitingUsersOfLocker(locker.id).then(
                  response => {
                    // aggiorna gli utenti in attesa di conferma
                    this.$parent.awaitingUsers = response.data.message;
                  }
              );
              AuthService.getUsersOfLocker(locker.id).then(
                  response => {
                    // aggiorna gli utenti dell'armadio
                    this.$parent.$parent.$children[2].users = response.data.message;
                  }
              );
              this.$fire({
                title: this.$t('success'),
                text: this.$t('user_approved_succ'),
                type: "success",
                confirmButtonColor: "#fbbf24",
                timer: 3000
              });
            } else {
              this.$fire({
                title: this.$t('error'),
                html: this.$t('error_occurred') + ".<br>(" + response.data.message + ")",
                type: "error",
                confirmButtonColor: "#fbbf24",
                timer: 3000
              });
            }
          }
      );
    }
  },

  props: {
    index: Number,
  }
};
</script>
