<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4">
      <card-locker-subscribe/>
    </div>
    <div class="w-full mb-12 px-4">
      <card-lockers/>
    </div>
    <div class="w-full mb-12 px-4">
      <card-lockers-of-user/>
    </div>
  </div>
</template>
<script>
import CardLockers from "@/components/Cards/User/CardLockers.vue";
import CardLockersOfUser from "@/components/Cards/User/CardLockersOfUser.vue";
import CardLockerSubscribe from "@/components/Cards/User/CardLockerSubscribe.vue";

export default {

  components: {
    CardLockers,
    CardLockersOfUser,
    CardLockerSubscribe,
  }
};
</script>
