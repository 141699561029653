<template>
  <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
      :class="[color === 'light' ? 'bg-white' : 'bg-yellow-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="px-3 text-center flex justify-between">
        <h3
            class="font-semibold text-lg"
            :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
        >
          {{ locker.name }} - {{ $tc('user', 2) }}
        </h3>
        <div>
          <span
              class="z-10 leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3"
          >
            <i class="fas fa-search"></i>
          </span>
          <input
              type="text"
              :placeholder="$t('search')"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white mr-3 rounded text-sm shadow outline-none focus:outline-none focus:ring pl-10"
              v-model="filter"
          />
          <button
              class="bg-yellow-400 text-white ative:bg-yellow-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
              v-on:click="toggleAddUserToLocker()"
          >
            <i class="fas fa-plus text-sm mx-auto"></i>
          </button>
        </div>
      </div>
    </div>
    <card-add-user-to-locker v-if="showAddUserToLocker" />
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
        <tr>
          <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-yellow-800 text-yellow-300 border-emerald-700',
              ]"
          >
            {{ $t('name') }}
          </th>
          <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-yellow-800 text-yellow-300 border-emerald-700',
              ]"
          >
            {{ $t('surname') }}
          </th>
          <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-yellow-800 text-yellow-300 border-emerald-700',
              ]"
          >
            Email
          </th>
          <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-yellow-800 text-yellow-300 border-emerald-700',
              ]"
          ></th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(user, index) in filteredUsers">
          <tr v-if="user.id > 0" :key="user.id">
            <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <div v-if="user.name === ''">-</div>
              <div v-else>{{ user.name }}</div>
            </td>
            <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <div v-if="user.surname === ''">-</div>
              <div v-else>{{ user.surname }}</div>
            </td>
            <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <div v-if="user.email === ''">-</div>
              <div v-else>{{ user.email }}</div>
            </td>
            <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
            >
              <locker-users-dropdown v-bind:index="index"/>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import LockerUsersDropdown from "@/components/Dropdowns/LockerUsersDropdown.vue";
import CardAddUserToLocker from "@/components/Cards/Superuser/CardAddUserToLocker.vue";
import AuthService from "../../../services/auth.service";

export default {
  name: 'Users',
  data() {
    return {
      users: [],
      userIndex: '',
      locker: this.$parent.$props.locker,
      showAddUserToLocker: false,
      filter: '',
      AuthService,
    };
  },

  computed: {
    filteredUsers() {
      return this.users.filter(user => {
        const name = user.name.toString().toLowerCase();
        const surname = user.surname.toLowerCase();
        const email = user.email.toLowerCase();
        const searchTerm = this.filter.toLowerCase();

        return name.includes(searchTerm) || surname.includes(searchTerm) || email.includes(searchTerm);
      });
    }
  },

  mounted: function () {
    AuthService.getUsersOfLocker(this.locker.id).then(
        response => {
          this.users = response.data.message;
        }
    );
    window.setInterval(() => {
      AuthService.getUsersOfLocker(this.locker.id).then(
          response => {
            this.users = response.data.message;
          }
      );
    }, 20000)
  },

  methods: {
    toggleAddUserToLocker: function() {
      this.showAddUserToLocker = !this.showAddUserToLocker;
    },

    setIndex: function(index) {
      this.userIndex = index;
    }
  },

  components: {
    LockerUsersDropdown,
    CardAddUserToLocker,
  },

  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
