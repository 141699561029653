<template>
  <div></div>
</template>
<script>
import AuthService from "../services/auth.service";

export default {
  data() {
    return {
      AuthService,
    };
  },

  mounted() {
    AuthService.checkRecoverToken(this.$route.params.token).then(
      response => {
        localStorage.setItem('user', JSON.stringify({status: response.data.status, message: response.data.message.token}) )
        this.$router.push('/change_password');
      })
  }
};
</script>
