<template>
  <div class="flex flex-wrap mt-4 min-height-view">
    <div class="w-full mb-12 px-4">
      <card-superusers />
    </div>
  </div>
</template>
<script>
import CardSuperusers from "@/components/Cards/Superuser/CardSuperusers";

export default {
  components: {
    CardSuperusers,
  },
};
</script>
