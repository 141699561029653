<template>
  <div>
    <a
        class="text-blueGray-500 py-1 px-3"
        href="#pablo"
        ref="btnDropdownRef"
        v-on:click="toggleDropdown($event)"
    >
      <i class="fas fa-ellipsis-v"></i>
    </a>
    <div
        ref="popoverDropdownRef"
        class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
        v-click-outside="hideDropdown"
    >
      <a
          @click="generateQrcode"
          href="javascript:void(0);"
          class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        {{ $t('generate_qr') }}
      </a>
      <a
          @click="deleteCode"
          href="javascript:void(0);"
          class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        {{ $t('delete') }}
      </a>
    </div>
  </div>
</template>
<script>
import { createPopper } from "@popperjs/core";
import AuthService from "../../../services/auth.service";

export default {
  data() {
    return {
      dropdownPopoverShow: false,
      dropdownClicksCounter: 0,
      AuthService
    };
  },

  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },

    hideDropdown: function () {
      this.dropdownClicksCounter++;
      if(this.dropdownClicksCounter === 2) {
        this.dropdownClicksCounter = 0;
        if(this.dropdownPopoverShow)
          this.dropdownPopoverShow = !this.dropdownPopoverShow;
      }
    },

    generateQrcode: function() {
      let drd = this.$parent.drdCodes[this.$props.index];
      this.$parent.qrcode = '003' + drd.code;
      this.$parent.toggleQrcode();
      let drr;
      for(let code of this.$parent.drrCodes) {
        if(code.deposit_id === drd.id)
          drr = code;
      }
      this.$parent.qrcode2 = '004' + drr.code;
      this.$parent.showQrcode2 = true;
    },

    deleteCode: function() {
      this.$fire({
        title: this.$t('confirm_delete'),
        text: this.$t('confirm_delete_code'),
        type: "question",
        confirmButtonColor: "#fbbf24",
        showCancelButton: true,
      }).then(response => {
        if (response.value === true) {
          // cancella il codice
          let code = this.$parent.drdCodes[this.$props.index];
          AuthService.deleteReturnDepositCode(code.id).then(
            response => {
              if (response.data.status === "success") {
                // aggiorna i codici in caso di successo
                AuthService.readReturnDepositCodes().then(
                  response => {
                    // aggiorna i codici in caso di successo
                    this.$parent.drdCodes = response.data.message.reverse();
                  });
                this.$fire({
                  title: this.$t('success'),
                  text: this.$t('code_deleted_succ'),
                  type: "success",
                  confirmButtonColor: "#fbbf24",
                  timer: 3000
                });
              } else {
                this.$fire({
                  title: this.$t('error'),
                  html: this.$t('error_occurred') + ".<br>(" + response.data.message + ")",
                  type: "error",
                  confirmButtonColor: "#fbbf24",
                  timer: 3000
                });
              }
            });
        }
      });
    }
  },

  props: {
    index: Number,
  }
};
</script>
