<template>
  <section class="relative w-full h-full py-16 min-h-screen">
    <div class="absolute top-0 w-full h-full bg-yellow-300 bg-no-repeat bg-full"></div>
    <div class="container mx-auto px-4 h-full">
      <div class="flex content-center items-center justify-center h-full">
        <div class="w-full lg:w-4/12 px-4">
          <div
              class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
            <div class="rounded-t mb-0 px-6 py-6 text-center mx-auto font-bold text-3xl">
              <img class="mx-auto py-6" width="70%" alt="" :src="logo"/>
            </div>
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form @submit.prevent="sendEmail">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-gray-500 text-xs font-bold mb-2">
                    Email
                  </label>
                  <input
                      v-model="user.email"
                      type="email"
                      class="border-0 px-3 py-3 placeholder-emerald-300 text-gray-500 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      name="email"
                      placeholder="Email"
                  />
                </div>
                <div class="text-center mt-6">
                  <button
                      class="bg-yellow-400 text-white active:bg-yellow-300 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                  >
                    {{ $t('send_email') }}
                  </button>
                </div>
                <div class="container mx-auto pt-2">
                  <div class="flex justify-center text-sm font-semibold">
                    {{ $t('no_account') }}
                    <router-link
                        to="/signup"
                        v-slot="{ href, navigate }"
                    ><a :href="href" @click="navigate" class="text-yellow-500 hover:text-yellow-300 ml-1">{{ $t('signup') }}</a>
                    </router-link>
                  </div>
                </div>
                <div>
                  <img class="mx-auto pt-8" width="70%" alt="" :src="poweredBy"/>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import logo from "@/assets/img/mylockersolution-logo.png";
import poweredBy from "@/assets/img/powered-by.png";
import AuthService from "../services/auth.service";
import User from "../models/user";

export default {
  name: 'Login',
  data() {
    return {
      logo,
      poweredBy,
      user: new User('', '', ''),
      AuthService,
    };
  },

  methods: {
    validateEmail(email) {
      let re = /\S+@\S+\.\S+/;
      return re.test(email);
    },

    sendEmail() {
      if(this.validateEmail(this.user.email)) {
        AuthService.sendEmailRecover(this.user.email).then(
            response => {
              if (response.data.status === "success") {
                this.$fire({
                  title: this.$t('success'),
                  text: this.$t('email_sent_succ'),
                  type: "success",
                  confirmButtonColor: "#fbbf24",
                  timer: 3000
                });
                this.$router.push('/login');
              } else {
                this.$fire({
                  title: this.$t('error'),
                  html: this.$t('error_occurred') + ".<br>(" + response.data.message + ")",
                  type: "error",
                  confirmButtonColor: "#fbbf24",
                  timer: 3000
                });
              }
            })
      } else {
        this.$fire({
          title: this.$t('error'),
          text: this.$t('insert_valid_email'),
          type: "error",
          confirmButtonColor: "#fbbf24",
          timer: 3000
        });
      }
    }
  }
};
</script>
